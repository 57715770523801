import React,{useState,useEffect} from 'react';
import {NavLink,useParams,useHistory} from 'react-router-dom';
import Page from '../Layout/Page';
import storage from '../utils/storage';
import Service from '../Service';
import {MdArrowBack,MdEdit,MdClose} from 'react-icons/md';
import './style.css';
import {
    Card,
    CardBody,
    CardHeader,
    Row,
    Button,
    Col,
    Spinner,
    Badge,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import notify from '../utils/notify';

const ProductDetail=()=>{
    const [detail,setDetail]=useState({});
    const [multiUuid,setMultiUuid]=useState([]);
    const [filesArray,setFilesArray]=useState([]);
    const [deleteConfirmId,setDeleteConfirmId]=useState('');
    const [showFeatureImgLoader,setFeatureImgLoader]=useState(false);
    const [isLoader,setLoader]=useState(true);
    const params = useParams();
    const [isOpenModal,setOpenModal]=useState(false);
    const history = useHistory();
    const getDetail = async(uid="") => {
        try{
            var response = await Service.get('/product-detail',{productId:params.id});
            if(response.status===200){
                if(uid){
                    setDetail({...detail,productImages:response.data.data.productImages});
                    var newArray = filesArray.filter((item)=>{
                        return item.uid!==uid;
                    });
                    setFilesArray(newArray);
                    deletFileArray();
                }else{
                    setDetail(response.data.data);
                    setLoader(false);
                }
            }
        }catch(error){
            if(typeof error.response !=='undefined' && typeof error.response.status!=='undefined'){
                if(error.response.status===401){
                    storage.clear();
                    history.push('/login');
                }else{
                    notify.error(error.response.data.message);
                    return;
                }
            }else{
                notify.error(error.message,'Error');
                return;
            }
        }
    }
    const deletFileArray=async ()=>{
        var newArray=[];
        if(multiUuid.length){
            await Promise.all(filesArray.filter((item)=>{
                if(!(multiUuid.indexOf(item.uid) > -1)){
                    newArray.push(item);
                }
                return true;
            }));
        }
        setFilesArray(newArray);
    }
    
    useEffect(() => {
        getDetail();
    },[]);
    
    const deleteConfirmImages=async (id)=>{
        toggle();
        setDeleteConfirmId(id);
    }
    const removeImages=async ()=>{
        var images = detail.productImages.filter((item)=>{
            return item.id!==deleteConfirmId;
        });
        setDetail({...detail,productImages:images});
        try{
            await Service.delete('/delete-product-image',{
                productId: detail.id,
                imageId: deleteConfirmId,
            });
            toggle();
        }catch(error){
            toggle();
        }
    }
    const toggle = () => {
        setOpenModal((isOpenModal) => !isOpenModal);
        setDeleteConfirmId('');
    }
    const BrowseFile =(id)=>{
        document.getElementById(id).click();
    }
    const previewFiles= (input)=>{
        if(input.files.length){
            var file = input.files[0];
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend =async (e)=>{
                var img=e.target.result;
                setDetail({...detail,image:img});
                setFeatureImgLoader(true);
                var multipartdata=new FormData();
                multipartdata.append('image',file);
                multipartdata.append('productId',detail.id);
                try{
                    await Service.post('/upload-products-images',multipartdata,true);
                    setFeatureImgLoader(false);
                }catch(error){
                    setFeatureImgLoader(false);
                }
            };
        }
    }
    const previewAdditionalFiles= async(input)=>{
        if(input.files.length){
            var file = input.files[0];
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend =async (e)=>{
                var img=e.target.result;
                const uid = Math.random().toString(36).substr(2, 10);
                setFilesArray([...filesArray,{image:img,uid:uid,file:file}]);
                setMultiUuid([...multiUuid,uid]);
                UploadAdditionalImage(file,uid);
            };
            input.value='';
        }
    }
    const UploadAdditionalImage=async (file,uid)=>{
        var multipartdata=new FormData();
        multipartdata.append('additionalPhoto',file);
        multipartdata.append('productId',detail.id);
        try{
            await Service.post('/upload-products-images',multipartdata,true);
            getDetail(uid);
        }catch(error){
            getDetail(uid);
        }
        
    }
    return(
        <Page
            className=""
            title="Product Detail"
            button={
                <>
                <div className="btnproductdetail">
					<Button
                        className="btn-sm float-right"
                        onClick={()=>history.goBack()}
                    >
                    <MdArrowBack /> Back
                    </Button>
                    <NavLink to={`/edit-product/${params.id}`}>
                        <Button className="btn-sm float-right" style={{margin:'0 15px',background:'#fff'}}>
                        <MdEdit/> Edit
                        </Button>
                    </NavLink>
				</div>	
                </>
            }
            >
            <Card>
                <CardBody>
                    {isLoader?
                        <div className="text-center">
                            <Spinner />
                        </div>
                    :
                        <>
                            
                            <Row>
                                <Col md={12}>
                                    {detail.isApproved===1?
                                        <Badge color="success" style={{float:'right'}}> APPROVED </Badge>
                                    : detail.isApproved ===2 ?
                                        <Badge color="danger" style={{float:'right'}}> REJECTED </Badge>
                                    :
                                        <Badge color="warning" style={{float:'right'}}> PENDING </Badge>
                                    }
                                    <b className="detail-heading">Name : </b>
                                    <div className="text-muted">{detail.title}</div>
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row>
                                <Col md={3}>
                                    <b className="detail-heading">Brand : </b>
                                    <div className="text-muted">{detail.brand}</div>
                                </Col>
                                <Col md={3}>
                                    <b className="detail-heading">New With Tags : </b>
                                    <div className="text-muted">{detail.newWithTag}</div>
                                </Col>
                                <Col md={3}>
                                    <b className="detail-heading">Status : </b>
                                    <div className="text-muted">{detail.status===1?<span >Enabled</span>:<span >Disabled</span>}</div>
                                </Col>
                                <Col md={3}>
                                    <b className="detail-heading">Tags : </b>
                                    <div className="text-muted">
                                        {detail.tags !==null && detail.tags.length>0 && detail.tags.toString()}
                                    </div>
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row>
                                <Col md={12}>
                                    <b className="detail-heading">Description : </b>
                                    <div className="text-muted">{detail.description}</div>
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row>
                                <Col md={12}><b className="detail-heading">Sizes : </b></Col>
                                {detail.sizes.length?detail.sizes.map(item=>{
                                    return <Col key={item.id} md={2}><Card><CardBody style={{padding:'0.7rem'}}>{item.prefix||''} {item.size} : <b style={{color:item.available?'green':'red'}}>{item.available}</b></CardBody></Card></Col>
                                }):''}
                                
                            </Row>
                            <hr></hr>
                            <Row>
                                <Col md={12}>
                                    <b className="detail-heading">Price Detail : </b>
                                    <div className="text-muted mt-4" >
                                        <Row>
                                            <Col md={4}>
                                                <b className="detail-heading"> Original Price: ${detail.maxPrice}</b>
                                            </Col>
                                            <Col md={4}>
                                                <b className="detail-heading"> Listing Price: ${detail.sellingPrice}</b>
                                            </Col>
                                            <Col md={4}>
                                                <b className="detail-heading"> Shipping Price: ${detail.shippingCharge}</b>
                                            </Col>
                                            <Col md={4}>
                                                <b className="detail-heading"> Store Earning: ${detail.earning+detail.shippingCharge}</b>
                                            </Col>
                                            <Col md={4}>
                                                <b className="detail-heading"> Admin Commission: ${detail.adminCommission}</b>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row>
                                <Col md={12}>
                                    <b className="detail-heading">Colors : </b>
                                    <div className="text-muted mt-4">
                                        {detail.colors!==null && detail.colors.length>0 && detail.colors.map(color=>{
                                            if(color){
                                                return  <div style={{ width:'70px',float:'left'}} key={color.name}>
                                                    <div style={{
                                                        backgroundColor:color.colorCode,
                                                        border: '0.5px solid',
                                                        width:'20px',
                                                        height:'20px',
                                                        borderRadius:'50%',
                                                        marginRight:'10px'
                                                    }}></div> {color.name}
                                                </div>
                                            }else{
                                                return ``;
                                            }
                                        })}
                                    </div>
                                </Col>
                                
                            </Row>
                        </>
                    }
                </CardBody>
            </Card>
            {!isLoader && 
                <div className="mb-4">
                    <Card className="mt-4">
                        <CardHeader>Product Images</CardHeader>
                        <CardBody>
                            <Row>
                                {detail.image && <Col md={2} >
                                    <div>
                                        <MdEdit onClick={(e)=>{BrowseFile('feature-image')}} className="closeButtonClass" />
                                        <div className="drag-area" id="feature-image-area">
                                            {showFeatureImgLoader && <Spinner style={{position:'absolute'}} />}
                                            <img src={detail.image} alt="" />
                                            <input type="file" accept="image/*" onChange={(e)=>{previewFiles(e.target)}} id="feature-image" hidden />
                                        </div>
                                    </div>
                                </Col>}
                                {detail.productImages.length >0  && detail.productImages.map((item)=>{
                                    return (<Col md={2} key={'preview-images-'+item.id} className="">
                                        <div>
                                            <MdClose onClick={(e)=>{deleteConfirmImages(item.id)}} className="closeButtonClass" />
                                            <div className="drag-area" >
                                                <img src={item.image} alt="" />
                                            </div>
                                        </div>
                                    </Col> )
                                })}
                                {filesArray.map(item=>{
                                    return (<Col md={2} key={'preview-images-'+item.uid} className="colClass">
                                        <div style={{width:'150px', height:'150px'}}>
                                            <div className="drag-area" >
                                                <Spinner style={{position:'absolute'}} />
                                                <img src={item.image} alt="" />
                                            </div>
                                        </div>
                                    </Col> )
                                })}
                                <Col md={2} className="colClass" style={{width:'150px', height:'150px'}}>
                                    <div className="drag-area" style={{cursor:'pointer'}} onClick={()=>{BrowseFile('files')}}>
                                        <span className="header">+</span>
                                        <span className="header">Add Photo</span>
                                        <input type="file" accept="image/*" onChange={(e)=>{previewAdditionalFiles(e.target)}} id="files" hidden />
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            }
            <Modal
              isOpen={isOpenModal}
              toggle={toggle}
            >
                <ModalHeader toggle={toggle}>Delete Confirmation</ModalHeader>
                <ModalBody>
                    Are you sure? You want to delete this image.
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={removeImages}>
                    Confirm
                  </Button>{' '}
                  <Button onClick={toggle} color="secondary">
                    Cancel
                  </Button>
                </ModalFooter>
            </Modal>
        </Page>
    );
}

export default ProductDetail;