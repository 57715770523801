import './style/new-style.css';
import React from 'react';
import {BrowserRouter,Switch,Route} from 'react-router-dom';
import { MainLayout } from './components/Layout';
import Login from './components/Auth/Login';
import Dashboard from './components/Dashboard';
import StoreInfo from './components/Store/Info';
import ProductList from './components/Products/index';
import AddProduct from './components/Products/AddProduct';
import EditProduct from './components/Products/EditProduct';
import ProductDetail from './components/Products/Product-Detail';
import Orders from './components/Sales/Order';
import OrderReturnRequests from './components/Sales/OrderReturnRequests';
import OrderReplaceRequests from './components/Sales/OrderReplaceRequests';
import OrderReturnRequestsDetail from './components/Sales/OrderReturnRequestsDetail';
import OrderReplaceRequestsDetail from './components/Sales/OrderReplaceRequestsDetail';
import ShippingLabel from './components/Sales/ShippingLabel';
import DownloadReports from './components/Reports/Download';
import OrderDetail from './components/Sales/OrderDetail';
import Followers from './components/Followers/GetFollowers';
import Following from './components/Following/GetFollowing';
import Earnings from './components/Payments/Earnings';
import LashTraderSupport from './components/Supports/LashTrader';
import RecentChat from './components/Supports/RecentChat';
import ChangePassword from './components/Profile/ChangePassword';
import { ToastContainer } from 'react-toastify';
import './style/reduction.scss';


function App() {
  return (
    <BrowserRouter>
        <Switch>
            <Route exact={true} path="/login" component={()=><Login />} />
            <MainLayout>
              <Route exact={true} path="/" component={()=><Dashboard />} />
              <Route exact={true} path="/store-info" component={()=><StoreInfo />} />
              <Route exact={true} path="/products" component={()=><ProductList />} />
              <Route exact={true} path="/add-product" component={()=><AddProduct />} />
              <Route exact={true} path="/edit-product/:id" component={()=><EditProduct />} />
              <Route exact={true} path="/product-detail/:id" component={()=><ProductDetail />} />
              <Route exact={true} path="/orders" component={()=><Orders />} />
              <Route exact={true} path="/orders/generate-shipping-label/:id" component={()=><ShippingLabel />} />
              <Route exact={true} path="/order-return-requests" component={()=><OrderReturnRequests />} />
              <Route exact={true} path="/order-return-requests/:id" component={()=><OrderReturnRequestsDetail />} />
              <Route exact={true} path="/order-detail/:id" component={()=><OrderDetail />} />
              <Route exact={true} path="/followers" component={()=><Followers />} />
              <Route exact={true} path="/following" component={()=><Following />} />
              <Route exact={true} path="/change-password" component={()=><ChangePassword />} />
              <Route exact={true} path="/order-replace-requests" component={()=><OrderReplaceRequests />} />
              <Route exact={true} path="/order-replace-requests/:id" component={()=><OrderReplaceRequestsDetail />} />
              <Route exact={true} path="/earning" component={()=><Earnings />} />
              <Route exact={true} path="/reports" component={()=><DownloadReports />} />
              <Route exact={true} path="/supports" component={()=><LashTraderSupport />} />
              <Route exact={true} path="/customer-recent-chat" component={()=><RecentChat />} />
            </MainLayout>
        </Switch>
        <ToastContainer  />
    </BrowserRouter>
  );
}

export default App;
