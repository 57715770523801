import React,{useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    Button,
    Spinner
} from 'reactstrap';
import logo from '../../assets/logo/logo.png';
import notify from '../utils/notify';
import storage from '../utils/storage';
import axios from 'axios';
import {STORE_BASE_URL} from '../Config';

const Login =  ()=>{
    const history =useHistory();
    if(storage.user()){
        history.push('/');
    }
    const [formdata,setFormdata]=useState({
        mobileNumber:'',
        password:''
    });
    const [showLoader,setLoader] = useState(false);
    const handleInputChange=(key, input)=>{
        setFormdata({...formdata,[key]:input.value});
    }
    const SubmitLoin=async (e)=>{
        e.preventDefault();
        if(formdata.mobileNumber===''){
            notify.error('Enter mobile number.');
            return;
        }
        if(formdata.password===''){
            notify.error('Enter password.');
            return;
        }
        e.target.disabled=true;
        setLoader(true);
        await axios({
            url:STORE_BASE_URL+'/login',
            method:'POST',
            data:formdata
        }).then(response=>{
            e.target.disabled=false;
            setLoader(false);
            if(response.data !==undefined){
                var userdata = response.data.data;
                var _token =userdata._token;
                storage.save('_token',_token);
                history.push('/');
            }
        }).catch(err=>{
            e.target.disabled=false;
            setLoader(false);
            if(typeof err.response !==undefined){
                var errors = err.response.data;
                notify.error(errors.message);
            }
        });
    }
    return (
        <div className="content">
            <Row className="justify-content-center" style={{height: '70vh',alignItems:'center',marginRight:0,marginLeft:0}}>
                <Col md={6} lg={4}>
                    <Card>
                        <CardBody>
                            <div className="text-center pb-4">
                                <img src={logo} className="rounded" style={{ width: 120, height: 60, cursor: 'pointer' }}
                                    alt="logo"
                                />
                                <h3 className="mt-4">Seller Login</h3>
                            </div>
                            <Form className="mb-4">
                                <FormGroup>
                                    <Label >Mobile Number</Label>
                                    <Input type="number" min="0" value={formdata.mobileNumber} onChange={(e)=>handleInputChange('mobileNumber',e.target)} placeholder="Enter Mobile Number" />
                                </FormGroup>
                                <FormGroup>
                                    <Label >Password</Label>
                                    <Input type="password" value={formdata.password} onChange={(e)=>handleInputChange('password',e.target)} placeholder="Enter Password" />
                                </FormGroup>
                                <Button
                                    type="submit"
                                    className="btn btn-secondary border-0 btn-block w-100"
                                    onClick={(e)=>SubmitLoin(e)}
                                >
                                    Login {showLoader && 
                                        <Spinner animation="border" className="btn-loader" />
                                    }
                                </Button>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}
export default Login;