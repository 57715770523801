import React,{useState,useEffect} from 'react';
import {useHistory,useParams} from 'react-router-dom';
import Page from '../Layout/Page';
import storage from '../utils/storage';
import {MdArrowBack, MdClose} from 'react-icons/md';
import { Button, Col, Row } from 'reactstrap';
import Select from 'react-select';
import './style.css';
import {
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import Service from '../Service';
import notify from '../utils/notify';

const EditProduct=()=>{
    const [pageLoader,setPageLoader]=useState(true);
    const [isLoader,setIsLoader]=useState(false);
    const [departments,setDepartments]=useState([]);
    const [formdata,setFormdata]=useState({
        title: '',
        description:'',
        brand:'',
        colors:[],
        newWithTag:1,
        tags:[],
        status:1,
        maxPrice:'',
        sellingPrice:'',
        shippingCharge:''
    });
    const history = useHistory();
    const params = useParams();
    const [formCategory,setFormCategory]=useState([]);
    const [colors,setColors]=useState([]);
    const [formBrands,setFormBrands]=useState('');
    const [editColor,setEditColor]=useState([]);
    const [editTags,setEditTags]=useState([]);
    const [searchBrands,setSearchBrands]=useState([]);
    const [tags,setTags]=useState([]);
    const [formCategoryTitle,setFormCategoryTitle]=useState('');
    const [sizes,setSizes]=useState([]);
    const [isSizeInputOpen, setIsSizeInputOpen] = useState(false);
    const [isCustomSizeOpen, setIsCustomSizeOpen] = useState(false);
    const [sizeIndex, setSizeIndex] = useState(0);
    const [selectedBtn, setSelectedBtn] = useState({
        selectedSizeTab : '',
        selectedSize : '',
        quantity : '',
        sizeTypesId : '',
        prefix : 'US'
    });
    const [sizeList, setSizeList] = useState([]);
    const [isErr, setIsErr] = useState({
        category : false,
        size : false,
        quantity : false
    });
    const [deleteSizeData,setDeleteSizeData]=useState({data : {id : '', productId :''}, isOpen : false});

    const catalog = async ()=>{
        try{
            var response = await Service.get('/get-catalog');
            var res = response.data;
            if(typeof res.data!==undefined){
                setDepartments(res.data.departments);
                var rescolors = res.data.colors;
                if(rescolors.length){
                    var colorData=[];
                    rescolors.map((color)=>{
                        return colorData.push({label:<div style={{display:'flex'}}>
                            <div style={{
                                backgroundColor:color.colorCode,
                                width:'20px',
                                height:'20px',
                                borderRadius:'50%',
                                marginRight:'10px'
                            }}></div> {color.name}
                        </div>,value:color.colorCode});
                    });
                    setColors(colorData);
                }
            } 
        }catch(error){
            if(typeof error.response !=='undefined' && typeof error.response.status!=='undefined'){
                if(error.response.status===401){
                    storage.clear();
                    history.push('/login');
                }else{
                    notify.error(error.response.data.message);
                    return;
                }
            }else{
                notify.error(error.message,'Error');
                return;
            }
        }
    }
    const getBrands= async (input)=>{
        setFormBrands(input.value);
        if(input.value){
            try{
                var response =await Service.post('/search-brands',{search:input.value});
                var res = response.data;
                if(typeof res.data!==undefined){
                    var brandstyle = document.getElementsByClassName('brands');
                    if(brandstyle.length){
                        brandstyle[0].style.display='block';
                    }
                    setSearchBrands(res.data);
                }else{
                    setSearchBrands([]);
                }
            }catch(error){
                setSearchBrands([]);
            }
        }
    }
    const getTags= async ()=>{
        try{
            var response =await Service.get('/get-tags');
            var res = response.data;
            if(typeof res.data!==undefined){
                if(res.data.length){
                    var tagData=[];
                    var data =res.data;
                    data.map((tag)=>{
                        return tagData.push({label:tag.name,value:tag.name});
                    });
                    setTags(tagData);
                }
            }else{
                setTags([]);
            }
        }catch(error){
            setTags([]);
        }
    }
    
    const getproductDetail= async ()=>{
        try{
            var response = await Service.get('/product-detail',{productId:params.id});
            var res = response.data;
            var setColor = [];
            if(typeof res.data!==undefined){
                var product =res.data;
                // console.log(product);
                if(product.sizes){
                    setSizeList(product.sizes)
                }
                if(product.categories){
                    var setCategory =[];
                    var setTitle =[];
                    product.categories.map((category,index)=>{
                        setCategory[index]=category.categoryId;
                        setTitle[index]=category.displayName;
                        return true;
                    });
                    setFormCategory(setCategory);
                    setFormCategoryTitle(setTitle.join(' > '));
                    if(setCategory.length>2)
                        getSizes(setCategory[1],setCategory[2]);
                    else
                        getSizes(setCategory[1],'');

                }
                if(product.colors){
                   
                    var editColor=[];
                    product.colors.map((color,index)=>{
                        setColor[index]=color.colorCode;
                        editColor[index]={value:color.colorCode,label:<div style={{display:'flex'}}>
                            <div style={{
                                backgroundColor:color.colorCode,
                                width:'20px',
                                height:'20px',
                                borderRadius:'50%',
                                marginRight:'10px'
                            }}></div> {color.name}
                        </div>}
                        return true;
                    });
                    // console.log(editColor);
                    setEditColor(editColor);
                    setFormdata({...formdata,colors:setColor});
                }
                if(product.tags){
                    var setEditTag=[]
                    if(Array.isArray(product.tags)){
                        product.tags.map((item,index)=>{
                            return setEditTag[index] = {label:item,value:item}
                        });
                    }else{
                        setEditTag.push({label:product.tags,value:product.tags});
                    }
                    setEditTags(setEditTag);
                }
                setFormBrands(product.brand);
                setFormdata({...formdata,
                    title: product.title,
                    description:product.description,
                    brand:product.brand,
                    newWithTag:product.newWithTag,
                    tags:[],
                    status:product.status,
                    maxPrice:product.maxPrice,
                    sellingPrice:product.sellingPrice,
                    shippingCharge:product.shippingCharge,
                    colors : setColor
                });
                setPageLoader(false);
            }
        }catch(error){
            if(typeof error.response !=='undefined' && typeof error.response.status!=='undefined'){
                if(error.response.status===401){
                    storage.clear();
                    history.push('/login');
                }else{
                    notify.error(error.response.data.message);
                    return;
                }
            }else{
                notify.error(error.message,'Error');
                return;
            }
        }
        
    }
    const getSizes=async (categoryId,featureCategoryId)=>{
        var response = await Service.post('/get-sizes',{categoryId:categoryId,featureCategoryId:featureCategoryId});
        if(typeof response.data!==undefined){
            var res = response.data;
            if(typeof res.data!==undefined){
                setSizes(res.data)
            } 
        }
    }
    useEffect(() => {
        catalog();
        getTags();
        getproductDetail();
        
    },[])
    const categoryDropdownToggle=() => {
       var departmentStyle =  document.getElementsByClassName('languages')[0].style;
       if(departmentStyle.display==='none' || departmentStyle.display===''){
        departmentStyle.display='block';
       }else{
        departmentStyle.display='none';
       }
    }
    const departmentToggle=(Liclass) => {
       var categoryClass =  document.getElementsByClassName(Liclass);
        if(categoryClass.length){
           var categoryStyle =categoryClass[0].style;
            if(categoryStyle.display==='none' || categoryStyle.display===''){
                categoryStyle.display='block';
            }else{
                categoryStyle.display='none';
            }
        }
        var closedepartment = document.getElementsByClassName('department');
        if(closedepartment.length){
            for(var i=0;i<closedepartment.length;i++){
                var departmentClassList =closedepartment[i].classList;
                if(departmentClassList[1]!==Liclass){
                    closedepartment[i].style.display='none';
                }
            }
        }
    }
    const categoryToggle=(Liclass,department=null,category=null,featureCategory=null,subCategory=null) => {
        var categoryClass =  document.getElementsByClassName(Liclass);
        if(categoryClass.length){
            if(subCategory==null && featureCategory==null){
                var closedepartment = document.getElementsByClassName('category_c');
                if(closedepartment.length){
                    for(var i=0;i<closedepartment.length;i++){
                        var departmentClassList =closedepartment[i].classList;
                        if(departmentClassList[1]!==Liclass){
                            closedepartment[i].style.display='none';
                        }
                    }
                }
            }
            var feature_category = document.getElementsByClassName('feature_category');
            if(feature_category.length){
                for(var j=0;i<feature_category.length;j++){
                    var feature_categoryClassList =feature_category[i].classList;
                    if(feature_categoryClassList[1]!==Liclass){
                        closedepartment[i].style.display='none';
                    }
                }
            }
           var categoryStyle =categoryClass[0].style;
            if(categoryStyle.display==='none' || categoryStyle.display===''){
                categoryStyle.display='block';
            }else{
                categoryStyle.display='none';
            }

            
        }else{
            var setCategoryData=[];
            var setFormCategoryTitleData="";

            if(department){
                setCategoryData.push(department.id);
                setFormCategoryTitleData+=department.displayName;
            }
            if(category){
                setCategoryData.push(category.id);
                if(setFormCategoryTitleData!== ''){
                    setFormCategoryTitleData+=" > "
                }
                setFormCategoryTitleData+=category.displayName;
            }
            if(featureCategory){
                setCategoryData.push(featureCategory.id);
                if(setFormCategoryTitleData!== ''){
                    setFormCategoryTitleData+=" > "
                }
                setFormCategoryTitleData+=featureCategory.displayName;
            }
            if(subCategory){
                setCategoryData.push(subCategory.id);
                if(setFormCategoryTitleData!== ''){
                    setFormCategoryTitleData+=" > "
                }
                setFormCategoryTitleData+=subCategory.displayName;
            }
            setFormCategory(setCategoryData);
            setFormCategoryTitle(setFormCategoryTitleData);
            setIsErr(false);
            categoryDropdownToggle();
            if(setCategoryData.length>2)
                getSizes(setCategoryData[1],setCategoryData[2]);
            else
                getSizes(setCategoryData[1],'');
        }
        
    }

    const sizeInputToggle = ()=>{
        if(formCategoryTitle === ''){
            setIsErr({...isErr, category : true});
            return;
        }
        setIsSizeInputOpen(isSizeInputOpen => !isSizeInputOpen);
        setIsErr(false);
    }

    const selectSize = (data) =>{
        //setSelectedBtn({...selectedBtn, });
        const { sizeTypesId, prefix } = sizes[sizeIndex];
        // console.log(sizeTypesId===null ? '' : sizeTypesId, prefix)
        setSelectedBtn({...selectedBtn, selectedSize : data,sizeTypesId : sizeTypesId===null ? '' : sizeTypesId, prefix : prefix});
    }

    const setTabIndex =(type)=>{
        const tabData = sizes.findIndex(item =>{
            return item.type === type
        })
        setSizeIndex(tabData)
        setIsCustomSizeOpen(false);
        setSelectedBtn({...selectedBtn, selectedSize : ''})
    }

    const customSizeHandle = () =>{
        setIsCustomSizeOpen(true);
    }

    const inputCloseHandle = () =>{
        setIsSizeInputOpen(false);
        setIsCustomSizeOpen(false);
    }

    const onSaveCustomInput = () =>{
        setIsSizeInputOpen(false);
        //console.log(selectedBtn.selectedSize)
    }

    const onAddSize = () =>{
        const {selectedSize, quantity} = selectedBtn;
        if(!selectedSize || !quantity) { 
            notify.error('Please select the size')
            return;
        }
        const uid = Math.random().toString(36).substr(2, 10);
        setSizeList([...sizeList, {uid : uid, size : selectedBtn.selectedSize ,sizeTypesId:selectedBtn.sizeTypesId,prefix:selectedBtn.prefix, available : selectedBtn.quantity}]);
        setSelectedBtn({...selectedBtn, quantity : '', selectedSize : '', sizeTypesId :''});
        setIsSizeInputOpen(false);
        // console.log(sizeList)
    }

    const onDeleteSizeList = async(data, btn) =>{
        btn.disabled = true;
        try{
            var response = await Service.delete('/delete-product-size',data);
            btn.disabled = false;
            setDeleteSizeData({...deleteSizeData, isOpen : false})
            var res = response.data;
            if(res.status!=='success'){
                notify.error(res.message);
            } 
        }catch(error){
            console.log(error.message)
        }
    }
    const selectBrand = (brandName)=>{
        setFormBrands(brandName);
        setFormdata({...formdata,brand:brandName});
        var brandStyle =  document.getElementsByClassName('brands')[0].style;
        brandStyle.display='none';
    }
    
    const handleChange= async (input,key)=>{
        var value =input.value;
        setFormdata({...formdata,[key]:value.trim()});
    }
    const colorChange= (input)=>{
        var colordata=[];
        input.map((color)=>{
            return colordata.push(color.value);
        });
        setFormdata({...formdata,'colors':colordata});
    }
    const changeStyleTag= (input)=>{
        var tagdata=[];
        input.map((tag)=>{
            return tagdata.push(tag.value);
        });
        setFormdata({...formdata,'tags':tagdata});
    }
    const changeNewWithTag= (input)=>{
        setFormdata({...formdata,'newWithTag':input.value});
    }
    const changeAvailablity= (input)=>{
        setFormdata({...formdata,'status':input.value});
    }

    const SubmitProduct=async (btn)=>{
        if(formdata.title===''){
            notify.error('Enter product name');
            return;
        }
        if(formdata.description===''){
            notify.error('Enter description');
            return;
        }
        if(formCategory.length===0){
            notify.error('Please choose category','Error');
            return;
        }
        if(formdata.maxPrice===''){
            notify.error('Enter original price.');
            return;
        }else if(isNaN(parseFloat(formdata.maxPrice))){
            notify.error('Original price must be number or decimal.');
            return;
        }
        if(formdata.sellingPrice===''){
            notify.error('Enter listing price.');
            return;
        }else if(isNaN(parseFloat(formdata.sellingPrice))){
            notify.error('Selling price must be number or decimal.');
            return;
        }

        if(formdata.shippingCharge===''){
            notify.error('Enter shipping charge.');
            return;
        }else if(isNaN(parseFloat(formdata.shippingCharge))){
            notify.error('Shipping price must be number or decimal.');
            return;
        }
        if(sizeList.length===0){
            notify.error('Enter product size.');
            return;
        }

        var setdata = formdata;
        setdata.productId = params.id;
        setdata.category=formCategory;
        setdata.brand=formBrands;
        var setSizeData=[];
        let isError='';
        if(sizeList.length){
          for(let i=0; i<sizeList.length;i++){
            let newsize = sizeList[i];
            if(isNaN(parseInt(newsize.available))){
              isError='Enter valid size';
              break;
            }
            if(parseInt(newsize.available)>999){
              isError='Enter max 999 available quantity';
              break;
            }
            setSizeData.push({
                available:newsize.available,
                sizeTypesId:newsize.sizeTypesId,
                prefix:newsize.prefix,
                size:newsize.size,
                itemSku:"",
                id : newsize.id || ''
            });
          }
        }
        if(isError){
            notify.error(isError);
          return;
        }
        setdata.productquantities=setSizeData;
        btn.disabled=true;
        setIsLoader(true);
        try{
            var response = await Service.post('/update-products',setdata);
            var res = response.data;
            if(res.status==='success'){
                btn.disabled=false;
                setIsLoader(false);
                history.push('/products');
            }else{
                notify.error(res.message);
            } 
        }catch(error){
            if(typeof error.response !=='undefined' && typeof error.response.status!=='undefined'){
                    if(error.response.status===401){
                    storage.clear();
                    history.push('/login');
                }else{
                    notify.error(error.response.data.message);
                    return;
                }
            }else{
                notify.error(error.message,'Error');
                return;
            }
        }
    }
    const onChangeSizeListQty = ({...params}) =>{
        const {e: {target: {value}}, index} = params;
        const newData = sizeList;
        newData[index].available = value;
        setSizeList(newData);
    }

    const OpenDeleteModal= (id, productId)=>{
        setDeleteSizeData({data : {id, productId}, isOpen : !deleteSizeData.isOpen});
    }
    const closeDeleteModal= async ()=>{
        setDeleteSizeData({id : '', isOpen : !deleteSizeData.isOpen});
    }
    const deleteConfirm = (btn) =>{
        const {data : {id, productId}} = deleteSizeData;
        const refresh = sizeList.filter(item =>{
            return item.id !== id
        })
        setSizeList(refresh);
        onDeleteSizeList({productId , productSizeId : id}, btn);
    }
    return(
        <Page
            className=""
            title="Edit Products"
            button={
                <Button
                    className="btn-sm float-right"
                    onClick={()=>history.goBack()}
                >
                <MdArrowBack /> Back
                </Button>
            }
            >
            <Card>
                <CardBody>
                {pageLoader ?
                    <div className="text-center"><Spinner /></div>
                :
                    <Form>
                        <FormGroup>
                            <Label >Name <span className="text-danger">*</span></Label>
                            <Input type="text" value={formdata.title} onChange={(e)=>handleChange(e.target,'title')} placeholder=""/>
                        </FormGroup>
                        <FormGroup>
                            <Label >Description <span className="text-danger">*</span></Label>
                            <Input type="textarea" value={formdata.description} onChange={(e)=>handleChange(e.target,'description')} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="productCategory">Category <span className="text-danger">*</span></Label>
                            <Input type="text" id="productCategory" style={{backgroundColor:'#ffffff'}} readOnly value={formCategoryTitle} onClick={categoryDropdownToggle} />
                            <ul className="languages">
                                {departments.length>0 && departments.map(department=>{

                                    return <li key={'department-'+department.id} >
                                        <div onClick={()=>{departmentToggle('department-'+department.id)}}>
                                            {department.displayName}
                                        </div>
                                        {department.categories.length>0 && <ul className={'department department-'+department.id} style={{display:'none'}}>
                                            {department.categories.map(category=>{

                                                return <li key={'category-'+category.id} >
                                                    <div onClick={()=>{categoryToggle('category-'+category.id,department,category)}}>
                                                        {category.displayName}
                                                    </div>
                                                    {category.featureCategories.length>0 && <ul className={'category_c category-'+category.id} style={{display:'none'}}>
                                                        {category.featureCategories.map(featureCategory=>{
                                                            return <li key={'feature-'+featureCategory.id}>
                                                                <div onClick={()=>{categoryToggle('feature-category-'+featureCategory.id,department,category,featureCategory)}}>
                                                                    {featureCategory.displayName}
                                                                </div>
                                                                {featureCategory.subCategories.length>0 && <ul className={'feature_category feature-category-'+featureCategory.id} style={{display:'none'}}>
                                                                    {featureCategory.subCategories.map(subCategory=>{
                                                                        return <li key={'subcategory-'+subCategory.id}>
                                                                            <div onClick={()=>{categoryToggle('subcategory-'+subCategory.id,department,category,featureCategory,subCategory)}}>
                                                                                {subCategory.displayName}
                                                                            </div>
                                                                        </li>
                                                                    })}
                                                                </ul>}
                                                            </li>
                                                        })}
                                                    </ul>}
                                                </li>

                                            })}
                                        </ul>}
                                    </li>

                                })}
                            </ul>
                        </FormGroup>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Colors</Label>
                                    <Select options={colors} defaultValue={editColor} onChange={colorChange} isMulti />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Brands</Label>
                                    <Input type="text" value={formBrands || ''}  onChange={(e)=>{getBrands(e.target);handleChange(e.target,'brand')}}/>
                                    {searchBrands.length>0 && <ul className="brands">
                                        {searchBrands.map((brand)=>{
                                            return <li onClick={()=>{selectBrand(brand.brandName)}} key={'brand'+brand.brandName}>{brand.brandName}</li>
                                        })}
                                    </ul>}
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <Row>
                                <Col md={6}>
                                    <Label>Size <span className="text-danger">*</span></Label>
                                    <Input readOnly type='text' value={selectedBtn.selectedSize} className='sizeInput' onClick={sizeInputToggle} />
                                    {isErr.category && <span className='errorMsg'> Please select category first </span>}
                                    {isSizeInputOpen && 
                                    <div className='sizeInputBox'>
                                        <div className='sizeTypeTab'>
                                            {sizes.map((item, i)=>{
                                                return( <span key={i} onClick={()=>setTabIndex(item.type)} className={sizes[sizeIndex].type === item.type ? 'selected' :''}>{item.type}</span> )})}
                                                <span className='customSizeBtn' onClick={customSizeHandle}>Custom</span>
                                        </div>

                                        {isCustomSizeOpen ?
                                        <div>
                                            <FormGroup>
                                                <div className='customField'>
                                                    <Input type='text' onChange = {(e)=>setSelectedBtn({...selectedBtn, selectedSize : e.target.value})} />
                                                    <Button type='button' onClick={onSaveCustomInput}>Save</Button>
                                                </div>        
                                            </FormGroup>
                                        </div>
                                        :
                                        <div className='options'>
                                            {/* {sizes.length === 0 && <span className='noSizes'> Please select the category first !</span>} */}
                                            {sizes.length >0 && sizes[sizeIndex].size.map((size,i)=>{
                                                return(
                                                    <button key={i} type='button' className={size===selectedBtn.selectedSize ? 'selected' : ''} onClick={()=>selectSize(size)}>{`${sizes[sizeIndex].prefix} ${size}`}</button>
                                                )
                                            })}
                                        </div>}
                                        <span className='closeInput' onClick={inputCloseHandle}>close</span>
                                    </div>}
                                </Col>
                                <Col md={5}>
                                    <Label>Available Quantity</Label>
                                    <Input type='number' min={1} value={selectedBtn.quantity} onChange= {(e)=>setSelectedBtn({...selectedBtn, quantity : e.target.value})} />
                                </Col>
                                <Button type='button' className='addSizeBtn' onClick={onAddSize}>Add</Button>
                            </Row>
                            <div className='sizeList'>
                                {sizeList.map((item,i)=>{
                                    return(
                                        <div key = {i+item.id} className='sizeListItem'>
                                            <div className='sizeArea'>
                                                <span className='label1'>Size : </span> <span className='label2'> {item.size} </span>
                                            </div>
                                            <div className='avlQuantityArea'>
                                                <span className='label1'> Available Quantity : </span> 
                                                <input type='text' maxLength={5} defaultValue={item.available} className='edit-field' onChange={(e)=>onChangeSizeListQty({e, index:i})} />
                                            </div>
                                            <div className='removeSize' onClick={()=>OpenDeleteModal(item.id, item.productId)}>
                                                {<MdClose />}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </FormGroup>
                        
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>New With Tags</Label>
                                    <Select onChange={changeNewWithTag} defaultValue={formdata.newWithTag?formdata.newWithTag==='Yes'?[{value:1,label:'Yes'}]:[{value:0,label:'No'}]:[]} options={[{value:1,label:'Yes'},{value:0,label:'No'}]} />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>Style Tags</Label>
                                    <Select onChange={changeStyleTag} defaultValue={editTags} options={tags} isMulti />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>Availability</Label>
                                    <Select onChange={changeAvailablity}  defaultValue={formdata.status?formdata.status===1?[{value:1,label:'For Sale'}]:[{value:0,label:'Not For Sale'}]:[]} options={[{value:1,label:'For Sale'},{value:0,label:'Not For Sale'}]} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr></hr>
                        <Label style={{fontSize:'20px',fontWeight:200}}>Price Details (Required)</Label>
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>Original Price <span className="text-danger">*</span></Label>
                                    <Input type="text" value={formdata.maxPrice} 
                                        onChange={(e)=>{handleChange(e.target,'maxPrice')}} 
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>Listing Price <span className="text-danger">*</span></Label>
                                    <Input type="text"  value={formdata.sellingPrice} 
                                        onChange={(e)=>{handleChange(e.target,'sellingPrice')}}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>Shipping Charge <span className="text-danger">*</span></Label>
                                    <Input type="text"  value={formdata.shippingCharge} 
                                        onChange={(e)=>{handleChange(e.target,'shippingCharge')}} 
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        
                        <hr></hr>
                        <Label style={{fontSize:'20px',fontWeight:200}}>Additional Details (Optional)</Label>
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>Listing SKU</Label>
                                    <Input type="text" value={formdata.listingSku} onChange={(e)=>{handleChange(e.target,'listingSku')}} />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>Cost Price</Label>
                                    <Input type="text" value={formdata.costPrice} onChange={(e)=>{handleChange(e.target,'costPrice')}} />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>Other Info</Label>
                                    <Input type="text" value={formdata.otherInfo} onChange={(e)=>{handleChange(e.target,'otherInfo')}}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup style={{float: "right"}}>
                            <Button type='button' className="btn-secondary" onClick={(e)=>SubmitProduct(e.target)}>Save {isLoader && <Spinner style={{height: '22px', width: '23px'}}/>}</Button>
                        </FormGroup>
                    </Form>
                }
                
                </CardBody>
            </Card>

            <Modal
                isOpen={deleteSizeData.isOpen}
                toggle={closeDeleteModal}
                >
                <ModalHeader toggle={closeDeleteModal}>Delete Confirmation</ModalHeader>
                <ModalBody className="text-center">
                    Are you sure? You want to delete this size.
                </ModalBody>
                <ModalFooter>
                <Button onClick={(e)=>deleteConfirm(e.target)} color="primary">
                    Confirm
                </Button>
                <Button onClick={closeDeleteModal} color="secondary">
                    Cancel
                </Button>
                </ModalFooter>
            </Modal>
        </Page>
    );
}

export default EditProduct;