import React from 'react';

const SourceLink = (props) => {
  const url="#"
  return (
    <a href={url} target="_blank" rel="noopener noreferrer" {...props} />
  );
};

export default SourceLink;
