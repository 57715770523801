const storage =  {
    get :(key)=>{
      const localData = localStorage.getItem(key);
      return localData || '{}';
    },
    delete :(key)=>{
      localStorage.removeItem(key);
      return true;
    },
    save :(key, value)=>{
      localStorage.setItem(key, value);
      return true;
    },
    user : (key="")=>{
        try {
            var parseJwt= JSON.parse(atob(storage.get('_token').split('.')[1]));
            if(key){
                if(typeof parseJwt[key]!==undefined){
                    return parseJwt[key];
                }else{
                    return '';
                }
            }else{
                return parseJwt;
            }
        } catch (e) {
            return null;
        }
    },
    clear:()=>{
        localStorage.clear();
        return true;
    }
}
export default storage;