import React, { useState,useEffect } from "react";
import { useHistory,useParams } from "react-router-dom";
import Page from "../Layout/Page";
import {Card,CardBody,Spinner,Row,Col,CardHeader,Input,Media,Button,Table} from 'reactstrap';
import DataTable from 'react-data-table-component';
import Service from '../Service';
import storage from '../utils/storage';
import notify from '../utils/notify';
import moment from 'moment';
import {MdArrowBack} from 'react-icons/md';
import { saveAs } from "file-saver";
import './style.css';

const OrderHistory = ()=>{
    const  history = useHistory();
    const  params = useParams();
    const [isLoader,setLoader]=useState(false);
    const [statusList,setStatusList] = useState(true);
    const [data,setData] = useState({});
    const [reloadPage,setReloadPage] = useState(false);
    const [labelBtn,setLabelBtn]=useState(true);
    const [productStatus,setProductStatus]= useState({
        orderProductId: '',
        orderStatus:''
    });

    const getOrderStatusList=async ()=>{
        try {
            var response = await Service.get('/get-order-status-list');
            if(typeof response.data !== undefined){
                var res = response.data;
                if(res.data !==undefined){
                    setStatusList(res.data);
                }
            }
        }catch(error){
            notify.error(error.message,'Error');
            return;
        }
    }
    const getOrderDetail = async ()=>{
        setLoader(true);
        try {
            var response = await Service.get('/get-order-detail',{orderId:params.id});
            if(typeof response.data !== undefined){
                var res = response.data;
                if(res.status==='success'){
                    var mapData = res.data.products;
                    let countP = 0;
                    mapData.map(item=>{
                        if(item.orderStatusId < 5){
                            countP+=1;
                        }
                        return true;
                    });
                    setData(res.data);
                    setLabelBtn(countP>0?false:true);
                    setLoader(false);
                }else{
                    setData({});
                    setLoader(false);
                }
            }
        }catch(error){
            setLoader(false);
            if(typeof error.response !=='undefined' && typeof error.response.status!=='undefined'){
                if(error.response.status===401){
                    storage.clear();
                    history.push('/login');
                }else{
                    notify.error(error.response.data.message);
                    return;
                }
            }else{
                notify.error(error.message,'Error');
                return;
            }
        }
    }
    useEffect(()=>{
        getOrderStatusList();
        getOrderDetail();
    },[reloadPage]);
    
    const orderProducts= [
        {   
            name: "Image",
            selector: (row)=>{
                return <Media style={{maxWidth:'50px',maxHeight:'50px'}} 
                object src={row.image} alt={row.title} />
            },
            sortable: false,
            center : true
        },{   
            name: "Title",
            selector: (row)=>{
                return row.title;
            },
            sortable: false,
            center : true,
            wrap: true,
        },{   
            name: "Item Price",
            selector: (row)=>{
                return '$'+row.sellingPrice;
            },
            sortable: false,
            center : true
        },{   
            name: "Quantity",
            selector: (row)=>{
                return row.quantity;
            },
            sortable: false,
            center : true
        },{   
            name: "Total Price",
            selector: (row)=>{
                return '$'+row.totalSellingPrice;
            },
            sortable: false,
            center : true
        },{   
            name: "Status",
            selector: (row)=>{
                return row.orderStatus||'';
            },
            sortable: false,
            center : true
        }
    ];
    const handleInputChange = (input,orderProductId)=>{
        setProductStatus({...productStatus,orderStatus:input.value,orderProductId:orderProductId})
    }
    const UpdateProductStatus = async (btn,orderPId)=>{
        try {
            var response = await Service.post('/update-order-product-status',{
                orderId:parseInt(params.id),
                orderProductId:orderPId,
                orderStatusId:productStatus.orderStatus,
            });
            if(typeof response.data !== undefined){
                setReloadPage(!reloadPage);
            }
        }catch(error){
            setLoader(false);
            if(typeof error.response !=='undefined' && typeof error.response.status!=='undefined'){
                if(error.response.status===401){
                    storage.clear();
                    history.push('/login');
                }else{
                    notify.error(error.response.data.message);
                    return;
                }
            }else{
                notify.error(error.message,'Error');
                return;
            }
        }
    }
    const GenerateShippingLabel = ()=>{
        if(data.shippingLabel){
            saveAs(
                data.shippingLabel,
                `${params.id}-shippingLabel.pdf`
            );
        }else{
            history.push('/orders/generate-shipping-label/'+data.id);
        }
    }
    const ExpandableComponent =(row)=>{
        var productData =row.data;
        return <div className="p-4" key={productData.id}>
            <Table className="table-bordered">
                <thead>
                    <tr>
                        <th>Status</th>
                        <th>Time</th>
                        <th>Reason</th>
                    </tr>
                </thead>
                <tbody>
                    {productData.history.length ? productData.history.map((history)=>{
                        return <tr key={history.name}>
                            <td>{history.name}</td>
                            <td>{moment(history.createdAt).format('DD MMM, YYYY hh:mm A')}</td>
                            <td>{history.reason}</td>
                        </tr>
                    }) :''}
                </tbody>
            </Table>
            {(productData.orderStatusId!==6 && productData.orderStatusId<5) ?
                <div className="row d-flex" >
                    <div className="col-md-10">
                        <Input type="select" className="form-control" disabled={productData.orderStatusId===5?true:false} onChange={(e)=>handleInputChange(e.target,productData.id)} value={productStatus.orderProductId===productData.id? productStatus.orderStatus :productData.orderStatusId} >
                            <option value="2" disabled={true} >Placed</option>
                            <option value="3" disabled={[4,3,5].includes(productData.orderStatusId)?true:false}>Processing</option>
                            <option value="4" disabled={[2,4].includes(productData.orderStatusId)?true:false}>Shipped</option>
                            <option value="5" disabled={[2,3,5].includes(productData.orderStatusId)?true:false}>Delivered</option>
                            <option value="6" disabled={[4,5].includes(productData.orderStatusId)?true:false}>Canceled</option>
                        </Input>
                    </div>
                    <div className="col-md-2">
                        <Button onClick={(e)=>UpdateProductStatus(e.target,productData.orderProductId)}>Update Status</Button>
                    </div>
                </div>
            :''}
        </div>
    }
    const downloadOrderInvoice = async (btn)=>{
        btn.disabled= true;
        try {
            var response = await Service.get('/get-order-invoice',{orderId:params.id});
            if(typeof response.data !== undefined){
                var resdata = response.data;
                saveAs(
                    resdata.data.orderInvoice,
                    `${params.id}-orderinvoice.pdf`
                );
                btn.disabled= false;
            }
        }catch(error){
            if(typeof error.response !=='undefined' && typeof error.response.status!=='undefined'){
                notify.error(error.response.data.message,'Error',{timeOut: 2000});
                return;
            }
        }
    }
    return (
        <Page
        className="OrdersPage"
        title="Order Details"
        breadcrumbs={[{ name: 'buttons', active: true }]}
        button={
            <Button
                className="btn-sm float-right"
                onClick={()=>history.goBack()}
            >
            <MdArrowBack /> Back
            </Button>
        }
        >
        {isLoader?

            <Card>
                <CardBody>
                    <div className="text-center">
                        <Spinner />
                    </div>
                </CardBody>
            </Card>
        :
            <>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>Order Products</CardHeader>
                            <DataTable
                                columns={orderProducts}
                                responsive={true}
                                persistTableHead={true}
                                data={data && data.products}
                                expandableRows
                                expandableRowExpanded={row => true}
                                expandableRowsComponent={ExpandableComponent}
                            />
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <div className="grid invoice">
                            <div className="grid-body d-flex" style={{justifyContent:'space-between'}}>
                                {data && data.shippingAddress &&
                                    <div className="col-xs-6">
                                        <address>
                                            <strong>Shipping Address:</strong><br />
                                            {data.shippingAddress.firstName||''} {data.shippingAddress.userLastName||''}<br />
                                            {data.shippingAddress.address||''}<br />
                                            {data.shippingAddress.city||''} {data.shippingAddress.state||''}<br />
                                            {data.shippingAddress.country||''}, {data.shippingAddress.postalCode||''}<br />
                                            <abbr title="Phone">P:</abbr> {data.shippingAddress.mobileNumber||''}
                                        </address>
                                    </div>
                                }
                            </div>
                        </div>
                    </Col>
                    <Col md={8}>
                        <Card>
                            <CardHeader>Payments Details</CardHeader>
                            <CardBody>

                                <Row>
                                    
                                    <Col md={3}>
                                        <span className="detail-heading">Item Price : </span>
                                        <div className="text-muted">{(data && data.totalItemsPrice) ? `$${data.totalItemsPrice}` : '-' }</div>
                                    </Col>
                                    
                                    <Col md={3}>
                                        <span className="detail-heading">Shipping Charge : </span>
                                        <div className="text-muted">{(data && data.shippingCharge) ? `$${data.shippingCharge}` : '-' }</div>
                                    </Col>
                                    <Col md={3}>
                                        <span className="detail-heading">Earning : </span>
                                        <div className="text-muted">{(data && data.totalEarning) ? data.totalEarning : '-'}</div>
                                    </Col>
                                    <Col md={3}>
                                        <span className="detail-heading">Admin Commission : </span>
                                        <div className="text-muted">{data && data.totalAdminCommission}</div>
                                    </Col>
                                    <hr />
                                    <Col md={3}>
                                        <span className="detail-heading">Net Earning : </span>
                                        <div className="text-muted">{data.shippingCharge?parseFloat(data.shippingCharge)+parseFloat(data.totalEarning):data.totalEarning}</div>
                                    </Col>
                                </Row>
                                                   
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <CardBody>
                    <Row className="justify-content-center">
                        <Button color="secondary" onClick={(e)=>downloadOrderInvoice(e.target)} className="mr-2" >Download Order Invoice</Button>
                        <Button color="secondary" disabled={labelBtn}  onClick={(e)=>GenerateShippingLabel(e.target)} className="mr-2" >Download Shipping Label</Button>
                    </Row>
                </CardBody>
            </>
        }
        </Page>
    )
}

export default OrderHistory;