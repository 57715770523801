import React,{useState,useEffect} from 'react';
import {Link,NavLink,useHistory} from 'react-router-dom';
import Page from "../Layout/Page";
import DataTable from "react-data-table-component";
import Service from '../Service';
import storage from '../utils/storage';
import notify from '../utils/notify';
import {
    Col,
    Row,
    Button,
    CardHeader,
    CardBody,
    Card,
    NavLink as BSNavLink,
    Spinner,
    Media,
    Input
} from 'reactstrap';
import moment from 'moment';
import {
    MdVisibility
} from 'react-icons/md';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

const OrderReturnRequests = () => {
    const [records,setRecords]=useState([]);
    const [currentPage,setCurrentPage]=useState(1);
    const [isLoad,setIsLoad]=useState(false);
    const [totalRecords,setTotalRecords]=useState(0);
    //const [search,setSearch]=useState('');
    const [statusM,setStatusM]=useState('pending');
    const [sort,setSort]=useState('id');
    const [sortDirection,setSortDirection]=useState('desc');
    const [fromDate,setFromDate]=useState(null);
    const [toDate,setToDate]=useState(null);
    const [callApi,setCallApi]=useState(false);
    const history = useHistory();
   
    const columns = [
        {
          name: "Order Id",
          sortField: "id",
          selector: function(data){
            return <Link to={`/order-detail/${data.orderId}`} style={{color:'#2586db'}}><b>#{data.orderId}</b></Link>
          },
          sortable: false,
          wrap: true,
        },
        {
            name: "Image",
            sortField: "image",
            selector: (row) => <Media src={row.orderProducts.image} alt={row.orderProducts.title} style={{width:'30px',height:'30px'}}/>,
            sortable: false,
            wrap: true,
        },
        {
            name: "Product",
            sortField: "title",
            selector: (row) => row.orderProducts.title,
            sortable: false,
            wrap: true,
        },
        {
          name: "Quantity",
          sortField: "quantity",
          selector: row=>row.orderProducts.quantity,
          sortable: false,
          wrap: true,
        },{
          name: "Amount",
          sortField: "amount",
          selector: row=> '$'+row.orderProducts.totalSellingPrice,
          sortable: false,
          wrap: true,
        },{
          name: "Request Status",
          sortField: "status",
          selector: row=>{
              if(row.requestStatus==='approved'){
                return <span className="badge badge-success">{row.status}</span>
              }else if(row.requestStatus==='declined'){
                return <span className="badge badge-danger"> {row.status} </span>
              }else{
                return <span className="badge badge-warning">{row.status}</span>
              }
          },
          sortable: false,
          wrap: true,
        },{
          name: "Created At",
          sortField: "createdAt",
          selector: (data)=>{
            return moment(data.createdAt).format('DD MMM, YYYY hh:mm A');
          },
          sortable: false,
          wrap: true,
        },
        {
            name: "Action",
            sortField:'action',
            selector: function(data){
                return <BSNavLink
                id={`order-return-requests-${data.id}`}
                className="btn btn-secondary btn-sm"
                tag={NavLink}
                style={{lineHeight:"0.8"}}
                to={`/order-return-requests/${data.id}`}
                activeClassName="active"
                exact={true}
              >
                <MdVisibility />
              </BSNavLink>
            },
            sortable: false,
            wrap:true,
        }
    ];
    
    const orders = async ()=>{
        setIsLoad(true);
        setRecords([]);
        try {
            var response = await Service.get('/order-product-return-requests',{
                page: currentPage,
                fromDate:fromDate||'',
                toDate: toDate||'',
                sort:sort,
                requestType:statusM,
                sortDirection:sortDirection
            });
            if(typeof response.data !== undefined){
                var res = response.data;
                setIsLoad(false);
                if(res.status==='success'){
                    setRecords(res.data);
                    setTotalRecords(res.count);
                }
            }
        }catch(error){
            setIsLoad(false);
            if(typeof error.response !=='undefined' && typeof error.response.status!=='undefined'){
                if(error.response.status===401){
                    storage.clear();
                    history.push('/login');
                }else{
                    notify.error(error.response.data.message);
                    return;
                }
            }else{
                notify.error(error.message,'Error');
                return;
            }
        }
    }
    useEffect(()=>{
        orders();
    },[callApi,currentPage,sort,sortDirection]);
    const handlePageChange=(page)=>{
        setCurrentPage(page);
    }
    
    const handleSort = async (column, sortD) => {
        setSort(column.sortField);
        setSortDirection(sortD);
    };
    const handleDatePickerApply = (event, picker) => {
        picker.element.val(
          picker.startDate.format('MM/DD/YYYY') +
            ' - ' +
            picker.endDate.format('MM/DD/YYYY')
        );
        if(picker.startDate!==null){
            setFromDate(moment(picker.startDate._d).format('YYYY-MM-DD'));
        }else{
            setFromDate(null);
        }
        if(picker.endDate!==null){
            setToDate(moment(picker.endDate._d).format('YYYY-MM-DD'));
        }else{
            setToDate(null);
        }
    };
    const handleCancel = (event, picker) => {
        picker.element.val('');
        setFromDate(null);
        setToDate(null);
    };
    
    const submitSearch=()=>{
        setCurrentPage(1);
        setCallApi(!callApi);
    }
    
    return (
        <Page
            className=""
            title="Order Return Requests"
        >
            <Card>
                <CardHeader>
                    <Row style={{marginTop:"13px",display:'flex',justifyContent:'end'}}>
                        <Col md={8} className="p-0 d-flex">
                            <Input type="select" className="col-md-2 form-control mr-2" onChange={(e)=>setStatusM(e.target.value)}>
                                <option value={'pending'}>Pending</option>
                                <option value={'approved'}>Approved</option>
                                <option value={'completed'}>Completed</option>
                            </Input>
                            <DateRangePicker
                                startDate={fromDate}
                                endDate={toDate}
                                initialSettings={{
                                    autoUpdateInput: false,
                                    locale: {
                                      cancelLabel: 'Clear',
                                    },
                                }}
                                locale={{ format: 'DD/MMM/YYYY' }}
                                onApply={handleDatePickerApply}
                                onCancel={handleCancel}
                            >
                                <input type="text" autoComplete="off" id="datarangepicker" placeholder="Choose Date.." className="form-control" />
                            </DateRangePicker>
                            <Button color="secondary" className="ml-3 mr-3" onClick={(e)=>{submitSearch()}}>Search</Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <DataTable
                                title=""
                                columns={columns}
                                responsive={true}
                                defaultSortField={sort}
                                defaultSortAsc={false}
                                persistTableHead={true}
                                progressPending={isLoad}
                                progressComponent={<Spinner color="danger" />}
                                data={records}
                                paginationRowsPerPageOptions={[10,20]}
                                pagination
                                paginationServer
                                paginationPerPage={10}
                                paginationTotalRows={totalRecords}
                                paginationDefaultPage={currentPage}
                                onChangePage={handlePageChange}
                                onSort={handleSort}
                                sortServer
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Page>
    );
}

export default OrderReturnRequests;