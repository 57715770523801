import React,{useState} from 'react';
import {useHistory} from 'react-router-dom';
import Page from '../Layout/Page';
import {
    Card,
    CardBody,
    Row,
    Form,
    FormGroup,
    Label,
    Input,
    Col,
    Spinner,
    Button
} from 'reactstrap';
import notify from '../utils/notify';
import Service from '../Service';

const ChangePassword = ()=>{
    const [isLoader,setIsLoader]=useState(false);
    const [form,setForm]=useState({
        password: '',
        confirmPassword:''
    });
    const history = useHistory();
    const handleInputChange= (input,key)=>{
        setForm({...form,[key]:input.value});
    }
    const SubmitChangePassword= async (btn)=>{
        if(form.password==="" || form.password===null){
            notify.error('Password is required.');
            return;
        }else if((form.password).length<4){
            notify.error('Password field minimum 4 character in length.');
            return;
        }
        if(form.confirmPassword==="" || form.confirmPassword===null){
            notify.error('Confirm password is required.');
            return;
        }
        if(form.password!==form.confirmPassword){
            notify.error('Password confirmation does not match with password.');
            return;
        }
        btn.disabled=true;
        setIsLoader(true);
        await Service.post('/change-password',{...form}).then(response=>{
            setIsLoader(false);
            btn.disabled=false;
            if(response.data.status==='success'){
                notify.success(response.data.message); 
                setForm({...form,password: '',confirmPassword:''});
            }else{
                notify.error(response.data.message);            
            }
        }).catch(error=>{
            setIsLoader(false);
            btn.disabled=false;
            if(typeof error.response !=='undefined' && typeof error.response.status!=='undefined'){
                if(error.response.status===401){
                    history.push('/login');
                }else{
                    notify.error(error.response.data.message);
                    return;
                }
            }
        });
    }
    return (
        <Page
            className=""
            title="Change Password"
        >
            <Card>
                <CardBody>
                    <Form>
                        <Row className="justify-content-center">
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Password</Label>
                                    <Input type="password" value={form.password} onChange={(e)=>{handleInputChange(e.target,'password')}} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Confirm Password</Label>
                                    <Input type="password"  value={form.confirmPassword} onChange={(e)=>{handleInputChange(e.target,'confirmPassword')}} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mt-2 justify-content-center">
                            <Col md={6}>
                                <FormGroup>
                                    <Button type='button' className="btn-secondary" onClick={(e)=>SubmitChangePassword(e.target)} >Save 
                                        {isLoader && <Spinner style={{height: '22px', width: '23px'}}/>}
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                        
                    </Form>
                </CardBody>
            </Card>
        </Page>
    )
}

export default ChangePassword;