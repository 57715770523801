import React,{useState,useEffect} from 'react';
import {useHistory,useParams} from 'react-router-dom';
import Page from '../Layout/Page';
import Avatar from '../Layout/Avatar';
import storage from '../utils/storage';
import Service from '../Service';
import {
    Col,
    Row,
    Button,
    CardBody,
    Card,
    Spinner,
    Table,
    CardHeader,
    CardFooter,
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
    ModalFooter,
    Input,
    Label
} from 'reactstrap';
import moment from 'moment';
import notify from '../utils/notify';
import './style.css';

const OrderReplaceRequestsDetail = () => {
    const params =useParams();
    const history = useHistory();
    const [records,setRecords]=useState({});
    const [openModal,setOpenModal]=useState(false);
    const [callApi,setCallApi]=useState(false);
    const [showRejectInput,setShowRejectInput]=useState(false);
    const [updateStatus,setUpdateStatus]=useState({
        status:'',
        declinedReason: ''
    });
    const orders = async ()=>{
        try {
            var response = await Service.get('/order-product-replace-requests-detail',{id:params.id});
            if(typeof response.data !== undefined){
                var res = response.data;
                if(res.status==='success'){
                    setRecords(res.data);    
                }
            }
        }catch(error){
            if(typeof error.response !=='undefined' && typeof error.response.status!=='undefined'){
                if(error.response.status===401){
                    storage.clear();
                    history.push('/login');
                }else{
                    notify.error(error.response.data.message);
                    return;
                }
            }else{
                notify.error(error.message,'Error');
                return;
            }
        }
    }

    useEffect(()=>{
        orders();
    },[callApi]);
    const ShowHeader = () => {
        var order =records.order;
        return <div className="grid invoice">
            <div className="grid-body d-flex" style={{justifyContent:'space-between'}}>
                <div className="col-xs-6">
                    <address>
                        <strong>From :</strong><br />
                        ID: #{order.storeId||''}<br />
                        {order.storeName||''}<br />
                    </address>
                </div>
                <div className="col-xs-6">
                    <address>
                        <strong>User Detail:</strong><br />
                        {order.userFirstName+' '+order.userFirstName}<br />
                        <abbr title="Phone">P:</abbr> {order.userMobileNumber} <br />
                        {order.userEmail}
                    </address>
                </div>
                <div className="col-xs-6">
                    <address>
                        <strong>Billing Address:</strong><br />
                        {order.billingAddress.firstName||''} {order.billingAddress.userLastName||''}<br />
                        {order.billingAddress.address||''}<br />
                        {order.billingAddress.city||''} {order.billingAddress.state||''}<br />
                        {order.billingAddress.country||''}, {order.billingAddress.postalCode||''}<br />
                        <abbr title="Phone">P:</abbr> {order.billingAddress.mobileNumber||''}
                    </address>
                </div>
                <div className="col-xs-6">
                    <address>
                        <strong>Shipping Address:</strong><br />
                        {order.shippingAddress.firstName||''} {order.shippingAddress.userLastName||''}<br />
                        {order.shippingAddress.address||''}<br />
                        {order.shippingAddress.city||''} {order.shippingAddress.state||''}<br />
                        {order.shippingAddress.country||''}, {order.shippingAddress.postalCode||''}<br />
                        <abbr title="Phone">P:</abbr> {order.shippingAddress.mobileNumber||''}
                    </address>
                </div>
                
            </div>
        </div>
    }
    const ShowProducts = ()=>{
        var products =records.orderProducts;
        return <>
             <Table>
                 <thead>
                    <tr>
                        <th>Image</th>
                        <th>Title</th>
                        <th>Qty</th>
                        <th>Total</th>
                        <th>Order Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><Avatar src={products.image} /></td>
                        <td>{products.title}</td>
                        <td>{products.quantity}</td>
                        <td>{products.totalSellingPrice}</td>
                        <td><span className={products.orderStatusId===19?'badge badge-warning':products.orderStatusId===20?'badge badge-success':'badge badge-secondary'}>{products.orderStatus}</span></td>
                    </tr>
                </tbody>
            </Table>
            <Col md={12}>
                <p><b>Replacement Date </b> : {moment(records.createdAt).format('MMM DD, YYYY hh:MM A')}</p>
                <p><b>Replacement Reason</b> : {records.reason||''}</p>
                {records.moreDetails? <p><b>More Details</b> :{records.moreDetails} </p>:''}
            </Col>
            <CardHeader>
                Uploaded Images
            </CardHeader>
            <CardBody>
                {records.photos && records.photos.map((item,index)=>{
                    return <img style={{width:'200px',height:'200px'}} key={index} src={item} alt="Products" />
                })}
                
            </CardBody>
         
            {products.orderStatusId===19?
                <CardFooter>
                    <Col className="mt-4" md={12}>
                        <div className="text-center">
                            <Button color="primary" className="mr-2" onClick={(e)=>{handleStatusClick('approved')}}>Approve</Button>
                            <Button color="danger" onClick={(e)=>{handleStatusClick('declined')}}>Reject</Button>
                        </div>
                    </Col>
                </CardFooter>
            :''}
        </>
    }
    const toggleModal=() => {
        setOpenModal(!openModal);
    }
    const handleRejectInput = async (input)=>{
        setUpdateStatus({...updateStatus,declinedReason:input.value});
    }
    const handleStatusClick= (status)=>{
        setUpdateStatus({...updateStatus,status:status});
        if(status==='approved'){
            setShowRejectInput(false);
        }else{
            setShowRejectInput(true);
        }
        toggleModal();
    }
    const updateStatusConfirmation= async (btn)=>{
        var setdata={
            status:updateStatus.status,
            declinedReason:updateStatus.declinedReason,
            id:params.id
        }
        try {
            var response = await Service.post('/order-product-replace-request-status',setdata);
            if(response.status===200){
                notify.success('Status updated Successfully');
                toggleModal();
                setCallApi(!callApi)              
            }
        }catch(error){
            return;
        }
    }
    return (
        <Page
            className=""
            title="Order Replace Requests Detail"
        >
            {Object.keys(records).length ?
                <>
                    <ShowHeader />
                    <Card>
                        <ShowProducts />
                    </Card>
                </>
                : <Card>
                    <CardBody>
                        <div className="text-center">
                            <Spinner />
                        </div>
                    </CardBody>
                </Card>
            }
            <Modal
                isOpen={openModal}
                toggle={toggleModal}
                >
                <ModalHeader toggle={toggleModal}>{updateStatus.status.toLocaleUpperCase()} CONFIRMATION</ModalHeader>
                <ModalBody>
                    {showRejectInput ? 
                        <FormGroup>
                            <Label>Message</Label>
                            <Input type="textarea" onChange={(e)=>{handleRejectInput(e.target)}} />
                        </FormGroup>
                    :   <Row className="justify-content-center">
                            <Label >Are you sure? You want to approve this store</Label>
                        </Row>
                    }
                </ModalBody>
                <ModalFooter>
                <Button onClick={(e)=>{updateStatusConfirmation(e.target)}} color="primary">
                    Confirm
                </Button>
                <Button onClick={toggleModal} color="secondary">
                    Cancel
                </Button>
                </ModalFooter>
            </Modal>
        </Page>
    );
}

export default OrderReplaceRequestsDetail;