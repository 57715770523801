/* eslint-disable react-hooks/exhaustive-deps */
import React,{useState,useEffect,useRef} from 'react';
import {useHistory} from 'react-router-dom';
import Page from '../Layout/Page';
import {
    Card,
    CardBody,
    Row,
    Col,
    Spinner,
    Media,
} from 'reactstrap';
import Service from '../Service';
import notify from '../utils/notify';
import storage from '../utils/storage';
import moment from 'moment';
import socket from '../utils/socket'; 
import './lash-trader-style.css';

const LashTrader = ()=>{
    const url="#";
    const history = useHistory();
    const [pageLoader,setPageLoader]=useState(false);
    const [loadCount,pageLoadCount]=useState(0);
    const [sellerChat,setSellerChat]=useState([]);
    const sellerChatRef =useRef(sellerChat);
    const [nextPage,setNextPage]=useState(false);
    const [scrollEvents,setScrollEvents]=useState(false);
    const [page,setPage]=useState(1);
    const [sendFileLoader,setSendFileLoader]=useState(false);
    const [sendForm,setSendForm]=useState({
		type:'text',message:''
	});
    useEffect(() => {
		socket.on("seller-receive-message-admin", data => {
           // console.log('Socket Data' , data);
			setChatResponse(data);
		});
	},[socket]);
    const setChatResponse = (data) =>{
        if(parseInt(data.storeId) === parseInt(storage.user('storeId'))){
            setSellerChat(createChatGroup([...sellerChatRef.current,data]));
            setTimeout(() => {
                const elem= document.getElementById('chat-message-list');
                elem.scrollTop = elem.scrollHeight;
            }, 0);
        }
    }
    const getSellerChat = async ()=>{
        setPageLoader(true);
        try {
            var response = await Service.get('/get-admin-chat',{page});
            if(typeof response.data !== undefined){
                var res = response.data;
                if(loadCount<1){
                    if(res.status==='success'){
                        sellerChatRef.current=res.data;
                        setSellerChat(createChatGroup(res.data));
                        setNextPage(res.nextPage);
                    }else{
                        setSellerChat([]);
                    }
                    pageLoadCount(1);
                    setPageLoader(false);
                    setTimeout(() => {
                        const elem= document.getElementById('chat-message-list');
                        elem.scrollTop = elem.scrollHeight;
                    }, 0);
                }else{
                    setPageLoader(false);
                    if(res.status==='success'){
                        sellerChatRef.current=[...res.data,...sellerChatRef.current];
                        const newChat = createChatGroup(sellerChatRef.current);
                        setSellerChat([...newChat]);
                        setNextPage(res.nextPage);
                    }
                    setTimeout(() => {
                        document.getElementById('history-'+res.data.pop().id).scrollIntoView();
                        setScrollEvents(false);
                    }, 0);
                }
            }
        }catch(error){
            setPageLoader(false);
            if(typeof error.response !=='undefined' && typeof error.response.status!=='undefined'){
                if(error.response.status===401){
                    storage.clear();
                    history.push('/login');
                }else{
                    notify.error(error.response.data.message);
                    return;
                }
            }else{
                notify.error(error.message,'Error');
                return;
            }
        }
    }
    const createChatGroup=(data)=>{
		const groups = data.reduce((groups, itemdata) => {
			const date = itemdata.createdAt.split('T')[0];
			if (!groups[date]) {
			  groups[date] = [];
			}
			groups[date].push(itemdata);
			return groups;
		  }, {});
		var returnArray= Object.keys(groups).map((date) => {
			return {
				date,
				history: groups[date]
			};
		});
		return returnArray;
	}
    useEffect(()=>{
        getSellerChat();
    },[page]);
    const showChatDate=(chatDate)=>{
		var startDate = moment(chatDate);
		var currentDate = moment(new Date());
		var result = currentDate.diff(startDate,'days');
		if(result){
			return <div className="chat__time">{moment(chatDate).format('DD MMM, YYYY')}</div>
		}else{
			return <div className="chat__time">Today</div>
		}
	}
    const submitChatForm= (e) => {
		e.preventDefault();
		document.getElementById('chat-submit-btn').click();
	}
    const handleSendInput=(input)=>{
		setSendForm({...sendForm,message:input.value});
	}
    const sendFIles= async (files)=>{
		if(files.files.length){
			var file = files.files[0];
			var formData= new FormData();
			formData.append('docs',file);
			var type='';
			if(file.type.indexOf('image/') > -1){
				type='image';
			}else{
				type='video';
			}
			if(type==='image' || type==='video'){
				formData.append('type',type);
				setSendFileLoader(true);
                try{
                    var response = await Service.post('/send-message-admin',formData,true);
                    setSendForm({...sendForm,message:''});
					var res = response.data;
					if(typeof res.data!==undefined){
						socket.emit("seller-send-message-admin",res.data);
					}
                    setSendFileLoader(false);   
                }catch(error){
                    setSendFileLoader(false);
                    if(typeof error.response !=='undefined' && typeof error.response.status!=='undefined'){
                        if(error.response.status===401){
                            history.push('/login');
                        }else{
                            return;
                        }
                    }
                }
			}else{
				notify.error('Only image/video allowed.');
				return;
			}
		}
	}
    const sendMessage= async (btn)=>{
		if(sendForm.message){
            btn.disabled=true;
            try{
                var response = await Service.post('/send-message-admin',sendForm);
                setSendForm({...sendForm,message:''});
                btn.disabled=false;
				var res = response.data;
				if(typeof res.data!==undefined){
					socket.emit("seller-send-message-admin",res.data);
				}   
            }catch(error){
                btn.disabled=false;
				if(typeof error.response !=='undefined' && typeof error.response.status!=='undefined'){
					if(error.response.status===401){
						history.push('/login');
					}else{
						return;
					}
				}
            }
		}
	}
    useEffect(()=>{
        if(scrollEvents){
            setPage(page+1);
        }
    },[scrollEvents]);

    const ChatScroll = (scroll) => {
        if(scroll.scrollTop <50 && nextPage===true){
            setScrollEvents(true);
        }
    }
    return (
        <Page
            className=""
            title="Lash Trader Support"
        >
            <Card>
                <CardBody>
                    <Row>
                    <Col lg={12} md={12} sm={12} xs={12}> 
                        <div className={'home-page__content messages-page'}>
                            <Row className={"h-100"}>
                                <div className="chat col-12 col-md-12 col-lg-12 col-xl-12 px-0 pl-md-1">
                                    <div className="chat__container">
                                        <div className="chat__wrapper py-2 pt-mb-2 pb-md-3">
                                            <div className="chat__content pt-4 px-3" id="chat-message-list" onScroll={(e)=>ChatScroll(e.target)}>
                                                <div id="chat-spinner-">
                                                {pageLoader? 
                                                    <Col className="text-center" ><Spinner /></Col>
                                                :''}
                                                </div>
                                                <ul className="chat__list-messages" id="chat-message-append">
                                                    {sellerChat.length? sellerChat.map((chat,index)=>{
                                                        return <li key={'chat'+index} id={'chat'+index}>
                                                            {chat.date?
                                                                <>
                                                                    <div className="chat__time">{showChatDate(chat.date)}</div>
                                                                    {chat.history.map(item=>{
                                                                            return <div key={'history-'+item.id} id={'history-'+item.id} className={(item.messageFrom==='store'? (item.type==='text'? 'chat__bubble chat__bubble--me':'chat-doc__me'):(item.type==='text'? 'chat__bubble chat__bubble--you':'chat-doc__you'))}>{item.type==='image'?
                                                                                <div className="chatimg">
                                                                                    <a className="lightbox" href={'#lightbox-'+item.id}>
                                                                                        <img src={item.message} style={{maxWidth:'130px',maxHeight:'130px'}} alt={'Store ID'+item.storeId} />
                                                                                    </a> 
                                                                                    <div className="lightbox-target" id={'lightbox-'+item.id}>
                                                                                        <img src={item.message} alt="" />
                                                                                        <a  className="lightbox-close" href={url} ><span style={{display: 'none'}}>X</span></a>
                                                                                    </div>
                                                                                </div>
                                                                            :item.type==='video'?
                                                                                <div className="chatimg">
                                                                                    <a className="lightbox" href={'#lightbox-'+item.id}>
                                                                                        <video src={item.message} preload="none" type="video/mp4" width="130" height="130" controls></video>
                                                                                    </a> 
                                                                                    <div className="lightbox-target" id={'lightbox-'+item.id}>
                                                                                        <video src={item.message} preload="none" type="video/mp4" width="130" height="130" controls></video>
                                                                                        <a  className="lightbox-close" href={url} ><span style={{display: 'none'}}>X</span></a>
                                                                                    </div>
                                                                                </div>
                                                                            :item.message}
                                                                            <p className="mb-0" style={{textAlign:'right'}}><small>{moment(item.createdAt).format('hh:mm A')}</small></p>
                                                                        </div>
                                                                    })}
                                                                </>
                                                            :
                                                                <div id={'history-'+chat.id} className={(chat.messageFrom==='store'? (chat.type==='text'? 'chat__bubble chat__bubble--me':'chat-doc__me'):(chat.type==='text'? 'chat__bubble chat__bubble--you':'chat-doc__you'))}>{chat.type==='image'?
                                                                    <Media src={chat.message} style={{maxWidth:'130px',maxHeight:'130px'}} alt={'Store ID'+chat.storeId} />
                                                                    :chat.type==='video'?
                                                                        <video src={chat.message} preload="none" type="video/mp4" width="130" height="130" controls></video>
                                                                    :chat.message}
                                                                    <p className="mb-0" style={{textAlign:'right'}}><small>{chat.messageFrom} {moment(chat.createdAt).format('hh:mm A')}</small></p>
                                                                </div>
                                                            }
                                                        </li>
                                                        
                                                    }):''}
                                                </ul>
                                            </div>
                                            <div className="chat__send-container px-2 px-md-3 pt-1 pt-md-3">
                                                <div className="custom-form__send-wrapper">
                                                    <form onSubmit={(e)=>submitChatForm(e)}>
                                                        <input type="text" className="form-control custom-form" onChange={(e)=>handleSendInput(e.target)} value={sendForm.message} id="message" placeholder="Type here" autoComplete="off" />
                                                        <div className="custom-form__send-img">
                                                            {sendFileLoader?
                                                                <Spinner />
                                                            :
                                                                <svg xmlns="http://www.w3.org/2000/svg" onClick={(e)=>document.getElementById('file-input').click()} className="svg-icon svg-icon--send-img" viewBox="0 0 45.7 45.7">
                                                                    <path
                                                                        d="M6.6,45.7A6.7,6.7,0,0,1,0,39.1V6.6A6.7,6.7,0,0,1,6.6,0H39.1a6.7,6.7,0,0,1,6.6,6.6V39.1h0a6.7,6.7,0,0,1-6.6,6.6ZM39,4H6.6A2.6,2.6,0,0,0,4,6.6V39.1a2.6,2.6,0,0,0,2.6,2.6H39.1a2.6,2.6,0,0,0,2.6-2.6V6.6A2.7,2.7,0,0,0,39,4Zm4.7,35.1Zm-4.6-.4H6.6a2.1,2.1,0,0,1-1.8-1.1,2,2,0,0,1,.3-2.1l8.1-10.4a1.8,1.8,0,0,1,1.5-.8,2.4,2.4,0,0,1,1.6.7l4.2,5.1,6.6-8.5a1.8,1.8,0,0,1,1.6-.8,1.8,1.8,0,0,1,1.5.8L40.7,35.5a2,2,0,0,1,.1,2.1A1.8,1.8,0,0,1,39.1,38.7Zm-17.2-4H35.1l-6.5-8.6-6.5,8.4C22,34.6,22,34.7,21.9,34.7Zm-11.2,0H19l-4.2-5.1Z"
                                                                        fill="#f68b3c"
                                                                    />
                                                                </svg>
                                                            }
                                                        </div>
                                                        <input type="file" id="file-input" onChange={(e)=>sendFIles(e.target)} style={{display: 'none'}} accept="image/*,video/*" />

                                                        <button type="button" id="chat-submit-btn" onClick={(e)=>sendMessage(e.target)} className="custom-form__send-submit">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon svg-icon--send" viewBox="0 0 45.6 45.6">
                                                                <g>
                                                                    <path d="M20.7,26.7a1.4,1.4,0,0,1-1.2-.6,1.6,1.6,0,0,1,0-2.4L42.6.5a1.8,1.8,0,0,1,2.5,0,1.8,1.8,0,0,1,0,2.5L21.9,26.1A1.6,1.6,0,0,1,20.7,26.7Z" fill="#d87232" />
                                                                    <path
                                                                        d="M29.1,45.6a1.8,1.8,0,0,1-1.6-1L19.4,26.2,1,18.1a1.9,1.9,0,0,1-1-1.7,1.8,1.8,0,0,1,1.2-1.6L43.3.1a1.7,1.7,0,0,1,1.8.4,1.7,1.7,0,0,1,.4,1.8L30.8,44.4a1.8,1.8,0,0,1-1.6,1.2ZM6.5,16.7l14.9,6.6a2,2,0,0,1,.9.9l6.6,14.9L41,4.6Z"
                                                                        fill="#d87232"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </Row>  
                        </div>
                    </Col>  
                    </Row>
                </CardBody>
            </Card>
        </Page>
    )
}

export default LashTrader;