/* eslint-disable react-hooks/exhaustive-deps */
import React,{useState,useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import Page from '../Layout/Page';
import {
    Card,
    CardBody,
    Row,
    Form,
    FormGroup,
    Label,
    Input,
    Col,
    Spinner,
    Button
} from 'reactstrap';
import {MdEdit} from 'react-icons/md';
import Service from '../Service';
import notify from '../utils/notify';
import storage from '../utils/storage';
import './style.css';
const Info = ()=>{
    const [pageLoad,setLoad]=useState(false);
    const [isLoader,setIsLoader]=useState(false);
    const [storeData,setStoreData]=useState({
        id:'',
        storeName:'',
        storeEmail:'',
        storePhone:'',
        storeCity:'',
        storeState:'',
        stateCode:'',
        storeZipcode:'',
        description:'',
        storeLogo:'',
        storeBanner:'',
        country:'',
        countryCode:'',
        storeAddress:'',
    });
    const [showLogoLoader,setLogoLoader]=useState(false);
    const [showBannerLoader,setBannerLoader]=useState(false);

    const history = useHistory();

    const getAddress =async (code)=>{
        try{
            var response = await Service.post('/get-address-by-zipcode',{zipCode:code});
            var res = response.data;
            if(res.status==='success'){
                const address = res.data;
                setStoreData({
                    ...storeData,
                    //storeAddress:sStoreData.storeAddress||storeData.storeAddress,
                    storeCity:address.city||storeData.storeCity,
                    storeState:address.state||storeData.storeState,
                    stateCode:address.stateCode ||storeData.stateCode,
                    storeZipcode:address.postalCode||storeData.storeZipcode,
                    country:address.country ||storeData.country,
                    countryCode:address.countryCode||storeData.countryCode,
                });
            }  
        }catch(error){

        }
    }
    const handleInputChange = (input,key)=>{
        setStoreData({...storeData,[key]:input.value});
        if(key==='storeZipcode' && input.value.length >=5){
            var isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(input.value);
            if(isValidZip){
                getAddress(input.value);
            }
        }
    }
    const getProfile=async ()=>{
        setLoad(true);
        try{
            var response =  await Service.get('/get-profile');
            if(typeof response.data !== undefined){
                var resdata = response.data;
                var {
                    id,storeName,storeEmail,storePhone,storeCity,description,
                    storeZipcode,storeLogo,storeBanner,storeState,stateCode,storeAddress
                } = resdata.data;
                setStoreData({
                    id,storeName,storeEmail,storePhone,storeCity,description,
                    storeZipcode,storeLogo,storeBanner,storeState,stateCode,storeAddress
                });
                
                setLoad(false);
            }
        }catch(error){
            if(typeof error.response !=='undefined' && typeof error.response.status!=='undefined'){
                if(error.response.status===401){
                    storage.clear();
                    history.push('/login');
                }else{
                    notify.error(error.response.data.message);
                    return;
                }
            }else{
                notify.error(error.message);
                return;
            }
        }
    }
    
    useEffect(()=>{
        getProfile();
    },[]);
    const BrowseFile =(id)=>{
        document.getElementById(id).click();
    }
    const uploadStoreLogo= (input)=>{
        if(input.files.length){
            var file = input.files[0];
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend =async (e)=>{
                var img=e.target.result;
                setStoreData({...storeData,storeLogo:img});
                setLogoLoader(true);
                var multipartdata=new FormData();
                multipartdata.append('logo',file);
                multipartdata.append('storeId',storeData.id);
                try {
                    var response= await Service.post('/upload-logo',multipartdata,true);
                    if(response){
                        setLogoLoader(false);
                    }
                }catch (error) {
                    setLogoLoader(false);
                }
            };
        }
    }
    const uploadStoreBanner= (input)=>{
        if(input.files.length){
            var file = input.files[0];
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend =async (e)=>{
                var img=e.target.result;
                setStoreData({...storeData,storeBanner:img});
                setBannerLoader(true);
                var multipartdata=new FormData();
                multipartdata.append('banner',file);
                multipartdata.append('storeId',storeData.id);
                try {
                    var response= await Service.post('/upload-banner',multipartdata,true);
                    if(response){
                        setBannerLoader(false);
                    }
                }catch (error) {
                    setBannerLoader(false);
                }
            };
        }
    }
    const UpdateStoreInfo= async (btn)=>{
        if(storeData.storeName==="" || storeData.storeName===null){
            notify.error('Store name is required.','Error',{timeOut: 2000});
            return;
        }
        if(storeData.storeZipcode==="" || storeData.storeZipcode===null){
            notify.error('Store zipcode is required.','Error');
            return;
        }else if(!(/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(storeData.storeZipcode))){
            notify.error('Please enter valid zipcode.','Error',{timeOut: 2000});
            return;
        }
        if(storeData.storeCity==="" || storeData.storeCity===null){
            notify.error('Store city is required.','Error',{timeOut: 2000});
            return;
        }
        if(storeData.storeZipcode==="" || storeData.storeZipcode===null){
            notify.error('Store zipcode is required.','Error');
            return;
        }
        btn.disabled=true;
        setIsLoader(true);
        try{
            var response = await Service.post('/update-profile',{
                storeName:storeData.storeName,
                storeEmail:storeData.storeEmail,
                storePhone:storeData.storePhone,
                countryId:223,
                storeCity:storeData.storeCity,
                storeZipcode:storeData.storeZipcode,
                storeState:storeData.storeState,
                stateCode:storeData.stateCode,
                description:storeData.description,
                storeAddress:storeData.storeAddress,
            });
            if(response.status===200){
                setIsLoader(false);
                btn.disabled=false;
                notify.success('Store updated successfully');
            }
        }catch(error){
            setIsLoader(false);
            btn.disabled=false;
            if(typeof error.response !=='undefined' && typeof error.response.status!=='undefined'){
                if(error.response.status===401){
                    history.push('/login');
                }else{
                    notify.error(error.response.data.message);
                    return;
                }
            }else{
                notify.error(error.response.data.message);
                return;
            }
        };
    }
    return (
        <Page
            className=""
            title="Store Info"
        >
            <Card>
                <CardBody>
                    {pageLoad ? 
                        <div className="text-center">
                            <Spinner />
                        </div> 
                        :<Form>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Store Name</Label>
                                        <Input type="text" name="storeName" onChange={(e)=>{handleInputChange(e.target,'storeName')}} value={storeData.storeName||''} />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Store Email</Label>
                                        <Input type="text"  name="storeEmail" readOnly onChange={(e)=>{handleInputChange(e.target,'storeEmail')}} value={storeData.storeEmail||''}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Store Phone</Label>
                                        <Input type="text" name="storePhone" readOnly value={storeData.storePhone||''}/>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Store Zipcode</Label>
                                        <Input type="text" name="storeZipcode" onChange={(e)=>{handleInputChange(e.target,'storeZipcode')}} value={storeData.storeZipcode||''}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Store City</Label>
                                        <Input type="text" name="storeCity" disabled={storeData.storeCity?true:false} onChange={(e)=>{handleInputChange(e.target,'storeCity')}} value={storeData.storeCity||''}/>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Store State</Label>
                                        <Input type="text"  name="storeState" disabled={storeData.storeState?true:false}  onChange={(e)=>{handleInputChange(e.target,'storeState')}} value={storeData.storeState||''} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Address</Label>
                                        <Input type="text" name="storeAddress" onChange={(e)=>{handleInputChange(e.target,'storeAddress')}} value={storeData.storeAddress||''}/>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Country</Label>
                                        <Input type="text"  name="country" disabled={storeData.country?true:false} onChange={(e)=>{handleInputChange(e.target,'country')}} value={storeData.country||'United States'} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label>Description</Label>
                                        <Input type="textarea" name="description" onChange={(e)=>{handleInputChange(e.target,'description')}} value={storeData.description||''}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2} className="colClass" style={{width:'150px', height:'150px'}}>
                                    <MdEdit onClick={()=>{BrowseFile('logoimage')}} className="closeButtonClass" />
                                    <div className="drag-area" style={{cursor:'pointer'}} >
                                        {showLogoLoader && <Spinner style={{position:'absolute'}} />}
                                        <img src={storeData.storeLogo} alt="Store Logo" />
                                        <input type="file" accept="image/*" onChange={(e)=>{uploadStoreLogo(e.target)}} id="logoimage" hidden />
                                    </div>
                                    <div style={{border: 0,display: 'flex',alignItems: 'center',
                                        justifyContent: 'center',flexDirection: 'column',background:'#a090b9'}}>
                                            Store Logo
                                    </div>
                                </Col>
                                <Col md={2} className="colClass" style={{width:'150px', height:'150px'}}>
                                    <MdEdit onClick={()=>{BrowseFile('bannerinamge')}} className="closeButtonClass" />
                                    <div className="drag-area"  >
                                        {showBannerLoader && <Spinner style={{position:'absolute'}} />}
                                        <img src={storeData.storeBanner} alt="Store Logo" />
                                        <input type="file" accept="image/*" onChange={(e)=>{uploadStoreBanner(e.target)}} id="bannerinamge" hidden />
                                    </div>
                                    <div style={{display: 'flex',alignItems: 'center',
                                        justifyContent: 'center',flexDirection: 'column',background:'#a090b9'}}>
                                            Store Banner
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col md={12}></Col>
                            </Row>
                            <hr></hr>
                            <Row className="mt-4">
                                <Col md={12}>
                                    <FormGroup>
                                        <Button type='button' className="btn-secondary" onClick={(e)=>{UpdateStoreInfo(e.target)}}>Update {isLoader && <Spinner style={{height: '22px', width: '23px'}}/>}</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                            
                        </Form>
                    }
                </CardBody>
            </Card>
        </Page>
    )
}

export default Info;