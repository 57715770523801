import React,{useEffect,useState} from 'react';
import Page from '../Layout/Page';
import {useHistory} from 'react-router-dom';
import storage from '../utils/storage';
import notify from '../utils/notify';
import Service from '../Service';
import { saveAs } from "file-saver";
import {
    Spinner,
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input
} from 'reactstrap';
import {MdVisibility,MdAdd} from 'react-icons/md';
import moment from 'moment';
import DataTable from "react-data-table-component";

const Earnings = ()=>{
    const history = useHistory();
    const [isLoad,setLoad]=useState(false);
    const [callApi,setCallApi]=useState(false);
    const [totalRecords,setTotalRecords]=useState(0);
    const [currentPage,setCurrentPage]=useState(1);
    const [sendRequestBtn,setSendRequestBtn]=useState(false);
    const [earning,setEarning]=useState({
        totalEarning: 0,
        totalWithdraw: 0,
        totalAdminCommission: 0,
        pendingEarning: 0,
        totalPendingWithdraw: 0,
        pendingAdminCommission: 0,
    });
    const [requestDetail,setRequestDetail]=useState({});
    const [formRequest,setFormRequest] = useState({
        requestAmount:'',
        requestComment:'',
        bankDetail:{
            accountHolderName:'',
            accountNumber:'',
            bankCode:''
        }
    });
    const [modalIsOpen,setIsOpenModal]=useState(false);
    const [showModalLoader,setShowModalLoader]=useState(false);
    const [dataSet,setData]=useState([]);
    const getEarning=async ()=>{
        setLoad(true);
        try {
            var response = await Service.get('/income');
            if(typeof response.data !== undefined){
                var res = response.data;
                if(res.status==='success'){
                    setEarning(res.data);
                    setSendRequestBtn(res.isSendRequest);
                    setLoad(false);
                }else{
                    setLoad(false);
                }
            }
        }catch(error){
            setLoad(false);
            if(typeof error.response !=='undefined' && typeof error.response.status!=='undefined'){
                if(error.response.status===401){
                    storage.clear();
                    history.push('/login');
                }else{
                    notify.error(error.response.data.message);
                    return;
                }
            }else{
                notify.error(error.message,'Error');
                return;
            }
        }

    }
    const getWithdrawList = async ()=>{
        try {
            var response = await Service.get('/get-withdraw-list');
            if(typeof response.data !== undefined){
                var res = response.data;
                if(res.status==='success'){
                    setData(res.data);
                    setTotalRecords(res.count);
                }
            }
        }catch(error){
            if(typeof error.response !=='undefined' && typeof error.response.status!=='undefined'){
                if(error.response.status===401){
                    storage.clear();
                    history.push('/login');
                }else{
                    notify.error(error.response.data.message);
                    return;
                }
            }else{
                notify.error(error.message,'Error');
                return;
            }
        }
    }
    useEffect(()=>{
        getEarning();
        getWithdrawList();
    },[callApi]);
    const columns = [
        {
          name: "Request Amount",
          selector: function(data){
            return data.requestAmount
          },
          sortable: false,
        },
        {
          name: "Request Comment",
          selector: function(data){
              return data.requestComment;
          },
          sortable: false,
          wrap: true,
        },
        {
            name: "Status",
            selector: function(data){
                return data.status;
            },
            sortable: false,
            wrap: true,
        },
        {
            name: "Request At",
            selector: function(data){
                return moment(data.createdAt).format('MMM DD, YYYY');
            },
            sortable: false,
            wrap: true,
        },
        {
            name: "Action",
            selector: function(data){
                return <div className="d-flex">
                    <Button
                        color="primary"
                        className="btn-sm mr-1"
                        onClick={(e)=>handleModal(data.id)}
                        style={{lineHeight:"0.5"}}
                    >
                        <MdVisibility />
                    </Button>
                </div>
            },
            sortable: false,
            wrap: true,
        }
    ];
    const handleModal =async (id) => {
        setIsOpenModal(true);
        setShowModalLoader(true);
        try {
            var response = await Service.get('/withdraw-request-detail',{id});
            if(typeof response.data !== undefined){
                var res = response.data;
                if(res.status==='success'){
                    setRequestDetail(res.data);
                }
                setShowModalLoader(false);
            }
        }catch(error){
            if(typeof error.response !=='undefined' && typeof error.response.status!=='undefined'){
                if(error.response.status===401){
                    storage.clear();
                    history.push('/login');
                }else{
                    notify.error(error.response.data.message);
                    return;
                }
            }else{
                notify.error(error.message,'Error');
                return;
            }
        }
    }
    const handleAddOpenModel =()=>{
        setIsOpenModal(!modalIsOpen);
    }
    const handleOpenModal = ()=>{
        setIsOpenModal(!modalIsOpen);
        setShowModalLoader(!showModalLoader);
    }
    const handlePageChange=(page)=>{
        setCurrentPage(page);
        setCallApi(!callApi)
    }
    const DownloadPaySlip = async (url)=>{
        let ext = url.split('.').pop();
        saveAs(
            url,'payslip.'+ext
        );
    }
    const handleAddFormInput= async (input,key)=>{
        setFormRequest({...formRequest,[key]:input.value});
    }
    const handleAddBankInput= async (input,key)=>{
        setFormRequest({
            ...formRequest,
            bankDetail:{
                ...formRequest.bankDetail,
                [key]:
                (key==='bankCode')?(input.value).toUpperCase():input.value
            }
        });
    }
    const sendPaymentRequest = async (btn)=>{
        const {requestAmount,requestComment,bankDetail:{bankCode,accountHolderName,accountNumber}} =formRequest;
        if(requestAmount==='' && parseInt(requestAmount)>0){
            notify.error('Request amount is required.');
            return;
        }
        if(parseFloat(earning.totalEarning.toFixed(2)) < parseFloat(requestAmount)){
            notify.error(`Amount must be less than or equal to $${earning.totalEarning}`);
            return;
        }
        if(accountHolderName===''){
            notify.error(`Account holder name is required.`);
            return;
        }
        if(accountNumber===''){
            notify.error(`Account number name is required.`);
            return;
        }
        if(bankCode===''){
            notify.error(`IFSC code is required`);
            return;
        }
        try {
            let response = await Service.post('/send-withdraw-request',{
                requestAmount,requestComment,bankCode,accountHolderName,accountNumber,
                saveBankDetails:'no'
            });
            let res = response.data;
            if(res.status==='success'){
                setIsOpenModal(!modalIsOpen);
                setFormRequest({
                    ...formRequest,
                    requestAmount:'',
                    requestComment:'',
                    bankDetail:{
                        ...formRequest.bankDetail,
                        bankCode:'',
                        accountHolderName:'',
                        accountNumber:'',
                    }
                });
                setCallApi(!callApi);
            }
        }catch(error){
            if(typeof error.response !=='undefined' && typeof error.response.status!=='undefined'){
                if(error.response.status===401){
                    storage.clear();
                    history.push('/login');
                }else{
                    notify.error(error.response.data.message);
                    return;
                }
            }else{
                notify.error(error.message,'Error');
                return;
            }
        }
    }
    return (
        <Page
            className=""
            title="Earnings"
            button={
                <div className="d-inline float-right">
                    {(earning && earning.totalEarning>0) ?
                        <Button
                            id={`add-payment-request`}
                            className="btn-secondary"
                            disabled={!sendRequestBtn}
                            onClick={handleAddOpenModel}
                        >
                            <MdAdd />Send Payment Request
                        </Button>
                    :""}
                </div>
              }
        >
        <Card>
            <CardBody>
                <Row className="mt-4">
                    <Col lg={4} md={4} sm={6} xs={12}>
                        <Card >
                            <CardBody>
                                <b className="detail-heading">Total Earning : </b>
                                <div className="text-card-d font-22">{isLoad ?<Spinner /> : '$'+earning.totalEarning.toFixed(2)}</div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4} md={4} sm={6} xs={12}>
                        <Card >
                            <CardBody>
                                <b className="detail-heading">Total Transfer : </b>
                                <div className="text-card-d font-22">{isLoad ?<Spinner /> : '$'+earning.totalWithdraw.toFixed(2)}</div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4} md={4} sm={6} xs={12}>
                        <Card >
                            <CardBody>
                                <b className="detail-heading">Total Balance : </b>
                                <div className="text-card-d font-22">{isLoad ?<Spinner /> : '$'+(earning.totalEarning-earning.totalWithdraw).toFixed(2)}</div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4} md={4} sm={6} xs={12}>
                        <Card >
                            <CardBody>
                                <b className="detail-heading">Admin Commission : </b>
                                <div className="text-card-d font-22">{isLoad ?<Spinner /> : '$'+(earning.totalAdminCommission).toFixed(2)}</div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4} md={4} sm={6} xs={12}>
                        <Card >
                            <CardBody>
                                <b className="detail-heading">Pending Balance : </b>
                                <div className="text-card-d font-22">{isLoad ?<Spinner /> : '$'+(earning.pendingEarning).toFixed(2)}</div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4} md={4} sm={6} xs={12}>
                        <Card >
                            <CardBody>
                                <b className="detail-heading">Pending Transfer Amount : </b>
                                <div className="text-card-d font-22">{isLoad ?<Spinner /> : '$'+(earning.totalPendingWithdraw).toFixed(2)}</div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <DataTable
                    title=""
                    columns={columns}
                    responsive={true}
                    persistTableHead={true}
                    progressPending={isLoad}
                    progressComponent={<Spinner color="danger" />}
                    data={dataSet}
                    paginationRowsPerPageOptions={[10]}
                    pagination
                    paginationServer
                    paginationPerPage={10}
                    paginationTotalRows={totalRecords}
                    paginationDefaultPage={currentPage}
                    onChangePage={handlePageChange}
                    sortServer
                />
                <Modal
                    isOpen={modalIsOpen}
                    size="lg"
                >
                    <ModalHeader >{Object.keys(requestDetail).length?'Payment Transfer Request':'Send Payment Request'}</ModalHeader>
                    <ModalBody className="m-4">
                    {showModalLoader ? <div className="text-center"><Spinner /></div> :
                        Object.keys(requestDetail).length?
                            <Form>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label >Request Amount</Label>
                                            <Input type="text" readOnly value={requestDetail.requestAmount}   />
                                        </FormGroup>
                                    </Col>
                                    {requestDetail.requestComment?
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label >Request Comments </Label>
                                            <Input type="text" readOnly value={requestDetail.requestComment}   />
                                        </FormGroup>
                                    </Col>
                                    : ''}
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Status</Label>
                                            <Input type="text" readOnly name="status" value={requestDetail.status}/>
                                        </FormGroup>
                                    </Col>
                                    {requestDetail.comments?
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label>Admin Comments</Label>
                                                <Input type="text" readOnly name="status" value={requestDetail.comments}/>
                                            </FormGroup>
                                        </Col>
                                    :''}
                                </Row>
                                <Row>
                                    <Col md={12} className="mt-4">
                                        <h4>Bank Detail</h4>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Account Holder Name</Label>
                                            <Input type="text" readOnly  value={requestDetail.bankDetail.accountHolderName}    />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Account Number</Label>
                                            <Input type="text" readOnly  value={requestDetail.bankDetail.accountNumber}    />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>IFSC Code</Label>
                                            <Input type="text" readOnly  value={requestDetail.bankDetail.bankCode}    />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                
                                <Row>
                                    {requestDetail.paySlip?
                                        <>
                                            <Col md={12} className="mt-4">
                                                <h4>Pay Slip</h4>
                                            </Col>
                                            <Col md={6}>
                                                <Button type='button' className="btn-secondary" onClick={(e)=>{DownloadPaySlip(requestDetail.paySlip)}} >
                                                    Download Pay Slip  
                                                </Button>
                                            </Col>
                                            
                                        </>
                                    :''}
                                </Row>
                            </Form>
                        :
                            <Form>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label >Request Amount <span className="text-danger">*</span></Label>
                                            <Input type="number" min={1}  value={formRequest.requestAmount} onChange={(e)=>handleAddFormInput(e.target,'requestAmount')}/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label >Request Comments (Optional)</Label>
                                            <Input type="text" value={formRequest.requestComment} onChange={(e)=>handleAddFormInput(e.target,'requestComment')}/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="mt-4">
                                        <h4>Bank Detail</h4>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Account Holder Name <span className="text-danger">*</span></Label>
                                            <Input type="text" value={formRequest.bankDetail.accountHolderName} onChange={(e)=>handleAddBankInput(e.target,'accountHolderName')}  />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Account Number <span className="text-danger">*</span></Label>
                                            <Input type="number" value={formRequest.bankDetail.accountNumber} onChange={(e)=>handleAddBankInput(e.target,'accountNumber')} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>IFSC Code <span className="text-danger">*</span></Label>
                                            <Input type="text" value={formRequest.bankDetail.bankCode} onChange={(e)=>handleAddBankInput(e.target,'bankCode')}/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                    }
                    </ModalBody>
                    <ModalFooter>
                        { !Object.keys(requestDetail).length ?
                            <>
                                <Button className="t-white" onClick={(e)=>sendPaymentRequest(e.target)} color="primary">
                                    Submit
                                </Button>
                                <Button onClick={handleAddOpenModel} color="secondary">
                                    Close
                                </Button>
                            </>
                        :   
                            <Button onClick={handleOpenModal} color="secondary">
                                Close
                            </Button>
                        }
                        
                    </ModalFooter>
                </Modal>
            </CardBody>
        </Card>      
        </Page>      
    )
}

export default Earnings;