import React,{useState,useEffect} from 'react';
import Page from '../Layout/Page';
import Avatar from '../Layout/Avatar';
import storage from '../utils/storage';
import notify from '../utils/notify';
import Service from '../Service';
import DataTable from "react-data-table-component";
import {
    Col,
    Row,
    Card,
    Spinner,
    CardBody,
    Button
} from 'reactstrap';
import { useHistory } from 'react-router-dom';

const GetFollowing = () => {
    const [data ,setData ] = useState([]);
    const [currentPage,setCurrentPage]=useState(1);
    const [totalRecords,setTotalRecords]=useState(0);
    const [isLoad,setIsLoad]=useState(false);
    const history =useHistory();

    const getFollowers=async () => {
        setIsLoad(true);
        try {
            var response = await Service.get('/get-following',{page:currentPage});
            if(typeof response.data !== undefined){
                var res = response.data;
                if(res.status==='success'){
                    setData(res.data);
                    setTotalRecords(res.count);
                    setIsLoad(false);
                }else{
                    setData([]);
                    setIsLoad(false);
                }
            }
        }catch(error){
            setIsLoad(false);
            if(typeof error.response !=='undefined' && typeof error.response.status!=='undefined'){
                if(error.response.status===401){
                    storage.clear();
                    history.push('/login');
                }else{
                    notify.error(error.response.data.message);
                    return;
                }
            }else{
                notify.error(error.message,'Error');
                return;
            }
        }
    }
    useEffect(() => {
        getFollowers();
    },[currentPage]);
      
    
    const handlePageChange=(page)=>{
        setCurrentPage(page);
    }
    const columns = [
        {
          name: "Photo",
          selector: function(row){
            return <Avatar src={row.avatar} alt="" />
          },
          sortable: false,
          wrap: true,
        },
        {
          name: "Customer Name",
          selector: function(data){
              return data.name;
          },
          sortable: false,
          wrap: true,
        },
        {
            name: "",
            selector: function(data,index){
                if(data.storeId){
                    return <Button
                        color="secondary t-white"
                        className="btn-sm mr-1"
                        style={{lineHeight:"0.5"}}
                        onClick={(e)=>manageFollowing(e.target,data.id,index)}
                    >
                        {data.isFollowing?'Following':'Follow'}
                    </Button>
                }else{
                    return ''
                }
            },
            sortable: false,
            wrap: true,
        }
    ];
    const manageFollowing = async (btn,userId,index)=>{
        const newData = data;
        const newDataIndex = newData[index];
        newDataIndex.isFollowing=newDataIndex.isFollowing?0:1;
        newData[index]=newDataIndex;
        setData([...newData]);
        btn.disabled=true;
        try {
            let response = await Service.cpost('/follow-store',{userId});
            if(typeof response.data !== undefined){
                btn.disabled=false;
            }
        }catch(error){
            btn.disabled=false;
        }
    }
    return(
        <Page
        className=""
        title="Following"
        breadcrumbs={[{ name: 'buttons', active: true }]}
     >
       <Card>
          <CardBody>
            <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                    <DataTable
                        title=""
                        columns={columns}
                        responsive={true}
                        persistTableHead={true}
                        progressPending={isLoad}
                        progressComponent={<Spinner color="danger" />}
                        data={data}
                        paginationRowsPerPageOptions={[10]}
                        pagination
                        paginationServer
                        paginationPerPage={10}
                        paginationTotalRows={totalRecords}
                        paginationDefaultPage={currentPage}
                        onChangePage={handlePageChange}
                        sortServer
                    />
                </Col>
            </Row>
          </CardBody>
      </Card>      
     </Page>
    );
}

export default GetFollowing;