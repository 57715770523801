import axios from "axios";
import {STORE_BASE_URL,CUSTOMER_BASE_URL} from "../Config";
import storage from '../utils/storage';

const Service ={
    get: async (serviceurl,params=null) => {
        var config = {
            url: STORE_BASE_URL+serviceurl,
            method: 'GET',
            headers: {
                Authorization:storage.get('_token')
            }
        }
        if(params){
            config.params=params;
        }
        return await axios(config);
    },
    cget: async (serviceurl,params=null) => {
        var config = {
            url: CUSTOMER_BASE_URL+serviceurl,
            method: 'GET',
            headers: {
                Authorization:storage.get('_token')
            }
        }
        if(params){
            config.params=params;
        }
        return await axios(config);
    },
    cpost: async (serviceurl,data,multipart=false) => {
        var headers ={
            Authorization:storage.get('_token')
        }
        if(multipart){
            headers['Content-Type'] = 'multipart/form-data'
        }else{
            headers['Content-Type'] = 'application/json';
        }
        var config = {
            url: CUSTOMER_BASE_URL+serviceurl,
            method: 'post',
            headers: headers,
            data: data
        }
        return await axios(config);
    },
    post :async (serviceurl,data,multipart=false) => {
        var headers ={
            Authorization:storage.get('_token')
        }
        if(multipart){
            headers['Content-Type'] = 'multipart/form-data'
        }else{
            headers['Content-Type'] = 'application/json';
        }
        var config = {
            url: STORE_BASE_URL+serviceurl,
            method: 'post',
            headers: headers,
            data: data
        }
        return await axios(config);
    },
    delete :async (serviceurl,data) =>{
        var config = {
            url: STORE_BASE_URL+serviceurl,
            method: 'delete',
            headers: {
                Authorization:storage.get('_token')
            },
            data: data
        }
        return await axios(config);
    }
}
export default Service;