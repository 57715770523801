import React,{useState,useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Card,CardBody,Row,Col,Spinner,CardHeader,Label} from 'reactstrap';
import Page from '../Layout/Page';
import Service from '../Service';
import storage from '../utils/storage';
import notify from '../utils/notify';
import icon1 from '../../assets/icons/icon-1.png';
import icon2 from '../../assets/icons/icon-2.png';
import icon3 from '../../assets/icons/icon-3.png';
import icon4 from '../../assets/icons/icon-4.png';
import icon5 from '../../assets/icons/icon-5.png';
import icon6 from '../../assets/icons/icon-6.png';
import icon7 from '../../assets/icons/icon-7.png';
import icon8 from '../../assets/icons/icon-8.png';
import icon9 from '../../assets/icons/icon-9.png';
import icon10 from '../../assets/icons/icon-10.png';
import icon11 from '../../assets/icons/icon-11.png';
import icon12 from '../../assets/icons/icon-12.png';
import icon13 from '../../assets/icons/icon-13.png';
import icon14 from '../../assets/icons/icon-14.png';
import icon15 from '../../assets/icons/icon-15.png';
import icon16 from '../../assets/icons/icon-16.png';
import icon17 from '../../assets/icons/icon-17.png';
const Dashboard =  ()=>{
  const history =useHistory();
  const [isLoad,setLoad] =useState(false);
  const [records,setRecords] =useState({
    totalProducts : 0,
    totalOrders : 0,
    totalFollowers : 0,
    totalFollowing : 0
  });
  const RedirectTo= async (url)=>{
    history.push(url);
  }
  const getDashboard = async ()=>{
    try{
      setLoad(true);
      var response = await Service.get('/dashboard');
      if(typeof response.data !== undefined){
        var resdata = response.data;
        if(typeof resdata.data !== undefined){
          setRecords({...records,totalProducts : resdata.data.products,
            totalOrders : resdata.data.orders,totalFollowers: resdata.data.followers,
            totalFollowing : resdata.data.following
          });
        }
        setLoad(false);
    }
    }catch(error){
      setLoad(true);
      if(typeof error.response !=='undefined' && typeof error.response.status!=='undefined'){
          if(error.response.status===401){
              storage.clear();
              history.push('/login');
          }else{
              notify.error(error.response.data.message);
              return;
          }
      }else{
        notify.error(error.message);
        return;
      }
    }
  }
  useEffect(()=>{
    getDashboard();
  },[]);
  return (
      <Page
      className="dashboardPage"
      title="Dashboard"
      breadcrumbs={[{ name: 'Dashboard', active: true }]}
    >
      <Row>
        <Col lg={3} md={3} sm={6} xs={12}>
          <Card onClick={()=>{RedirectTo('/products')}} style={{cursor:'pointer'}}>
            <CardBody>
            <b className="detail-heading">Total Products : </b>
            <div className="text-card-d">{isLoad ? <Spinner style={{width:'1rem', height:'1rem',border:'0.18em solid currentColor',borderRightColor:'transparent'}} /> :records.totalProducts}</div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={3} md={3} sm={6} xs={12}>
          <Card onClick={()=>{RedirectTo('/orders')}} style={{cursor:'pointer'}}>
            <CardBody>
            <b className="detail-heading">Total Orders : </b>
            <div className="text-card-d">{isLoad ? <Spinner style={{width:'1rem', height:'1rem',border:'0.18em solid currentColor',borderRightColor:'transparent'}} /> :records.totalOrders}</div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={3} md={3} sm={6} xs={12}>
          <Card onClick={()=>{RedirectTo('/followers')}} style={{cursor:'pointer'}}>
            <CardBody>
            <b className="detail-heading">Total Followers : </b>
            <div className="text-card-d">{isLoad ? <Spinner style={{width:'1rem', height:'1rem',border:'0.18em solid currentColor',borderRightColor:'transparent'}} /> :records.totalFollowers}</div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={3} md={3} sm={6} xs={12}>
          <Card onClick={()=>{RedirectTo('/following')}} style={{cursor:'pointer'}}>
            <CardBody>
            <b className="detail-heading">Total Following : </b>
            <div className="text-card-d">{isLoad ? <Spinner style={{width:'1rem', height:'1rem',border:'0.18em solid currentColor',borderRightColor:'transparent'}} /> :records.totalFollowing}</div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={4} md={4} sm={6} xs={12}>
          <Card>
            <CardHeader>
              Seller Account
            </CardHeader>
            <CardBody>
              <div className="d-flex mb-3">
                <img src={icon17} alt="" style={{width:'30px',height:'30px'}} />
                <Label className="ml-2 mt-1" style={{cursor:'pointer'}} onClick={()=>{RedirectTo('/orders')}} >Orders</Label>
              </div>
              <div className="d-flex mb-3">
                <img src={icon16} alt="" style={{width:'30px',height:'30px'}} />
                <Label className="ml-2 mt-1" >Admin Messages</Label>
              </div>
              <div className="d-flex mb-3">
                <img src={icon15} alt="" style={{width:'30px',height:'30px'}} />
                <Label className="ml-2 mt-1">Customer Enquiries</Label>
              </div>
              <div className="d-flex mb-3">
                <img src={icon14} alt="" style={{width:'30px',height:'30px'}} />
                <Label className="ml-2 mt-1">My Reviews</Label>
              </div>
              <div className="d-flex mb-3">
                <img src={icon13} alt="" style={{width:'30px',height:'30px'}} />
                <Label className="ml-2 mt-1">Downloads</Label>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={4} md={4} sm={6} xs={12}>
          <Card>
            <CardHeader>
              Seller Store
            </CardHeader>
            <CardBody>
                <div className="d-flex mb-3">
                  <img src={icon11} alt="" style={{width:'30px',height:'30px'}} />
                  <Label className="ml-2 mt-1">View Store</Label>
                </div>
                <div className="d-flex mb-3">
                  <img src={icon12} alt="" style={{width:'30px',height:'30px'}} />
                  <Label className="ml-2 mt-1" style={{cursor:'pointer'}} onClick={()=>{RedirectTo('/store-info')}}>Store Information</Label>
                </div>
                <div className="d-flex mb-3">
                  <img src={icon10} alt="" style={{width:'30px',height:'30px'}} />
                  <Label className="ml-2 mt-1" style={{cursor:'pointer'}} onClick={()=>{RedirectTo('/products')}}>Products</Label>
                </div>
                <div className="d-flex mb-3">
                  <img src={icon9} alt="" style={{width:'30px',height:'30px'}} />
                  <Label className="ml-2 mt-1" >Blog</Label>
                </div>
                <div className="d-flex mb-3">
                  <img src={icon8} alt="" style={{width:'30px',height:'30px'}} />
                  <Label className="ml-2 mt-1">Blog Comments</Label>
                </div>
                <div className="d-flex mb-3">
                  <img src={icon7} alt="" style={{width:'30px',height:'30px'}} />
                  <Label className="ml-2 mt-1" style={{cursor:'pointer'}} onClick={()=>{RedirectTo('/add-product')}}>Product Upload</Label>
                </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={4} md={4} sm={6} xs={12}>
          <Card>
            <CardHeader>
              Seller Payments
            </CardHeader>
            <CardBody>
                <div className="d-flex mb-3">
                  <img src={icon6} alt="" style={{width:'30px',height:'30px'}} />
                  <Label className="ml-2 mt-1">Payment</Label>
                </div>
                <div className="d-flex mb-3">
                  <img src={icon5} alt="" style={{width:'30px',height:'30px'}} />
                  <Label className="ml-2 mt-1">Commissions</Label>
                </div>
                <div className="d-flex mb-3">
                  <img src={icon4} alt="" style={{width:'30px',height:'30px'}} />
                  <Label className="ml-2 mt-1">Commission Invoices</Label>
                </div>
                <div className="d-flex mb-3">
                  <img src={icon3} alt="" style={{width:'30px',height:'30px'}} />
                  <Label className="ml-2 mt-1">Shipping Rates</Label>
                </div>
                <div className="d-flex mb-3">
                  <img src={icon2} alt="" style={{width:'30px',height:'30px'}} />
                  <Label className="ml-2 mt-1" >Returns</Label>
                </div>
                <div className="d-flex mb-3">
                  <img src={icon1} alt="" style={{width:'30px',height:'30px'}} />
                  <Label className="ml-2 mt-1">Seller Coupons</Label>
                </div>
            </CardBody>
          </Card>
        </Col>
      </Row>        
    </Page>
  )
}
export default Dashboard;