import React,{useState,useEffect} from 'react';
import {useHistory,useParams} from 'react-router-dom';
import Page from '../Layout/Page';
import storage from '../utils/storage';
import Service from '../Service';
import {
    Card,
    Spinner,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    Button
} from 'reactstrap';
import { saveAs } from "file-saver";
import notify from '../utils/notify';
import './style.css';


const ShippingLabel = () => {
    const params =useParams();
    const history = useHistory();
    const [records,setRecords]=useState({});
    const [isLoader,setIsLoader]=useState(false);
    const [formData,setFormdata]=useState({
        order_id:params.id,
        order_product_id:[],
        service_code:'',
        ship_to: {
            name: "",
            phone: "",
            address_line1: "",
            city_locality: "",
            state_province: "",
            postal_code: "",
            country_code: "",
            address_residential_indicator: "no"
        },
        ship_from: {
            name: "",
            company_name: "",
            phone: "",
            address_line1: "",
            city_locality: "",
            state_province: "",
            postal_code: "",
            country_code: "",
            address_residential_indicator: "no"
        },
        packages:[{
            weight: {
		        value: "",
		        unit: "ounce"
	        },
	        dimensions: {
		        height: "",
		        width: "",
		        length: "",
		        unit: "inch"
	        }
        }]
    });
    //const [callApi,setCallApi]=useState(false);
    const handleChange= async (input,key)=>{
        setFormdata({...formData,[key]:input.value});
    }
    const shippingFromChange= async (input,key)=>{
        setFormdata({...formData,ship_from:{...formData.ship_from,[key]:input.value}});
    }
    const shippingToChange= async (input,key)=>{
        setFormdata({...formData,ship_to:{...formData.ship_to,[key]:input.value}});
    }
    const packageChange= async (input,key,type)=>{
        var setPackageIndex =formData.packages;
        var setPackages = setPackageIndex[0];
        setPackages[type][key]=input.value;
        setPackageIndex[0]=setPackages;
        setFormdata({...formData,packages:setPackageIndex});
    }
    const onLoadFillForm = async (data) => {
        let storeDetail = storage.user();
        //console.log(storeDetail);
        let prd = [];
        await Promise.all(data.products.map(item=>{
            if(item.orderStatusId<5){
                prd.push(item.orderProductId);
            }
            return true;
        }))
        setFormdata({...formData,
            order_product_id:prd,
            ship_from:{
                name: (storeDetail.firstName+' '+storeDetail.firstName).trim() || "",
                company_name: storeDetail.storeName||"",
                phone: storeDetail.storePhone || "",
                address_line1: storeDetail.storeAddress || "",
                city_locality:  storeDetail.storeCity || "",
                state_province: storeDetail.stateCode || "",
                postal_code: storeDetail.storeZipcode || "",
                country_code: "US",
                address_residential_indicator:"no"
            },
            ship_to: {
                name: (data.shippingAddress.firstName || "") + (data.shippingAddress.lastName || ""),
                phone: data.shippingAddress.mobileNumber || "",
                address_line1: data.shippingAddress.address || "",
                city_locality: data.shippingAddress.city || "",
                state_province: "",
                postal_code: data.shippingAddress.postalCode,
                country_code: "US",
                address_residential_indicator: "no"
            },
        });
    }
    const orders = async ()=>{
        try {
            var response = await Service.get('/get-order-detail',{
                orderId: params.id,
            });
            var res = response.data;
            if(typeof res.data!=='undefined'){
                if(res.data.shippingLabel){
                    history.push('/order-detail/'+params.id);
                }
                onLoadFillForm(res.data);
                setRecords(res.data);               
            }   
        }catch(error){
            if(typeof error.response !=='undefined' && typeof error.response.status!=='undefined'){
                if(error.response.status===401){
                    storage.clear();
                    history.push('/login');
                }else{
                    notify.error(error.response.data.message);
                    return;
                }
            }else{
                notify.error(error.message,'Error');
                return;
            }
        }
        
    }

    useEffect(()=>{
        orders();
    },[]);
    const Generate =async (btn)=>{
        if(!formData.service_code){
            notify.error('Service code is required',{timeOut: 2000});
            return;
        }
        btn.disabled = true;
        setIsLoader(true);
        try {
            var response = await Service.post('/generate-shipping-label',formData);
            var res = response.data;
            btn.disabled= false;
            setIsLoader(false);
            if(typeof res.data!=='undefined'){
                saveAs(
                    res.data.pdf,
                    `${params.id}-shippingLabel.pdf`
                );
                setTimeout(() => {
                    history.push('/order-detail/'+params.id);
                }, 2000);            
            }               
        }catch(error){
            btn.disabled= false;
            setIsLoader(false);
            if(typeof error.response !=='undefined' && typeof error.response.status!=='undefined'){
                if(error.response.status===401){
                    storage.clear();
                    history.push('/login');
                }else{
                    notify.error(error.response.data.message);
                    return;
                }
            }else{
                notify.error(error.message,'Error');
                return;
            }
        }
    }
    return (
        <Page
            className=""
            title="Generate Shipping Label"
        >
            {Object.keys(records).length ?
                <>
                    <Card>
                        <CardBody>
                            <Form>
                                <FormGroup>
                                    <Label >Shipping Service <span className="text-danger">*</span></Label>
                                    <Input type="select" className="form-control" onChange={(e)=>handleChange(e.target,'service_code')} value={formData.service_code} placeholder="">
                                        <option value={''}>Choose..</option>
                                        <option value={'fedex_ground'}>FEDEX</option>
                                        <option value={'ups_ground'}>UPS</option>
                                    </Input>
                                </FormGroup>
                                <h3 style={{marginTop:"15px"}}>Shipping From</h3>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label >Name <span className="text-danger">*</span></Label>
                                            <Input type="text" value={formData.ship_from.name} onChange={(e)=>shippingFromChange(e.target,'name')} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label >Company Name <span className="text-danger">*</span></Label>
                                            <Input type="text" value={formData.ship_from.company_name} onChange={(e)=>shippingFromChange(e.target,"company_name")} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label >Phone <span className="text-danger">*</span></Label>
                                            <Input type="text" value={formData.ship_from.phone} onChange={(e)=>shippingFromChange(e.target,'phone')} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label >Address <span className="text-danger">*</span></Label>
                                            <Input type="text" value={formData.ship_from.address_line1} onChange={(e)=>shippingFromChange(e.target,'address_line1')} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label >City <span className="text-danger">*</span></Label>
                                            <Input type="text" value={formData.ship_from.city_locality} onChange={(e)=>shippingFromChange(e.target,'city_locality')} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label >State Province <span className="text-danger">*</span></Label>
                                            <Input type="text" value={formData.ship_from.state_province} onChange={(e)=>shippingFromChange(e.target,'state_province')} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label >Postal Code <span className="text-danger">*</span></Label>
                                            <Input type="text" value={formData.ship_from.postal_code} onChange={(e)=>shippingFromChange(e.target,'postal_code')} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label >Country Code <span className="text-danger">*</span></Label>
                                            <Input type="text" value={formData.ship_from.country_code} onChange={(e)=>shippingFromChange(e.target,'country_code')} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label >Address Residential Indicator <span className="text-danger">*</span></Label>
                                            <Input type="select" className="form-control" value={formData.ship_from.address_residential_indicator} onChange={(e)=>shippingFromChange(e.target,'address_residential_indicator')} >
                                                <option value={'no'} >No</option>
                                                <option value={'yes'} >yes</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <h3 style={{marginTop:"15px"}}>Shipping To</h3>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label >Name <span className="text-danger">*</span></Label>
                                            <Input type="text" value={formData.ship_to.name} onChange={(e)=>shippingToChange(e.target,'name')} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label >Phone <span className="text-danger">*</span></Label>
                                            <Input type="text" value={formData.ship_to.phone} onChange={(e)=>shippingToChange(e.target,'phone')} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label >Address <span className="text-danger">*</span></Label>
                                            <Input type="text" value={formData.ship_to.address_line1} onChange={(e)=>shippingToChange(e.target,'address_line1')} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label >City <span className="text-danger">*</span></Label>
                                            <Input type="text" value={formData.ship_to.city_locality} onChange={(e)=>shippingToChange(e.target,'city_locality')} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label >State Province <span className="text-danger">*</span></Label>
                                            <Input type="text" value={formData.ship_to.state_province} onChange={(e)=>shippingToChange(e.target,'state_province')} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label >Postal Code <span className="text-danger">*</span></Label>
                                            <Input type="text" value={formData.ship_to.postal_code} onChange={(e)=>shippingToChange(e.target,'postal_code')} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label >Country Code <span className="text-danger">*</span></Label>
                                            <Input type="text" value={formData.ship_to.country_code} onChange={(e)=>shippingToChange(e.target,'country_code')} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label >Address Residential Indicator <span className="text-danger">*</span></Label>
                                            <Input type="select" className="form-control" value={formData.ship_to.address_residential_indicator} onChange={(e)=>shippingToChange(e.target,'address_residential_indicator')} >
                                                <option value={'no'} >No</option>
                                                <option value={'yes'} >yes</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <h3 style={{marginTop:"15px"}}>Packages Detail</h3>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label >Weight <span className="text-danger">*</span></Label>
                                            <Input type="text" value={formData.packages[0].weight.value} onChange={(e)=>packageChange(e.target,'value','weight')} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label >Weight Unit <span className="text-danger">*</span></Label>
                                            <Input type="select" className="form-control" value={formData.packages[0].weight.unit} onChange={(e)=>packageChange(e.target,'unit','weight')} >
                                                <option value={'ounce'} >Ounces</option>
                                                <option value={'pound'} >Pound</option>
                                                <option value={'gram'} >Grams</option>
                                                <option value={'kilogram'} >Kilogram</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label >Height <span className="text-danger">*</span></Label>
                                            <Input type="text" value={formData.packages[0].dimensions.height} onChange={(e)=>packageChange(e.target,'height','dimensions')} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label >Width <span className="text-danger">*</span></Label>
                                            <Input type="text" value={formData.packages[0].dimensions.width} onChange={(e)=>packageChange(e.target,'width','dimensions')} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label >Length <span className="text-danger">*</span></Label>
                                            <Input type="text" value={formData.packages[0].dimensions.length} onChange={(e)=>packageChange(e.target,'length','dimensions')} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label >Unit <span className="text-danger">*</span></Label>
                                            <Input className="form-control" type="select" value={formData.packages[0].dimensions.unit} onChange={(e)=>packageChange(e.target,'unit','dimensions')} >
                                                <option value={'inch'} >Inch</option>
                                                <option value={'centimeter'} >Centimeter</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Button type='button' onClick={(e)=>Generate(e.target)} className="btn-secondary">Generate {isLoader && <Spinner style={{height: '22px', width: '23px'}}/>}</Button>
                                </FormGroup>
                            </Form>
                        </CardBody>
                    </Card>
                </>
                : <div className="text-center"><Spinner /></div>
            }
            
        </Page>
    );
}

export default ShippingLabel;