import React,{useState,useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import Page from '../Layout/Page';
import {
    Col,
    Row,
    Card,
    ListGroupItem,
	CardBody,
	Spinner
} from 'reactstrap';
import Service from '../Service';
import notify from '../utils/notify';
import storage from '../utils/storage';
import moment from 'moment';

const Chatting = ()=>{
    const [recentChat,setRecentChat]=useState([]);
    const [page,setPage]=useState(1);
    const [nextPage,setNextPage]=useState(false);
	const [pageLoader,setPageLoader]=useState(false);
	const history = useHistory();

	const getRecentChat=async ()=>{
        try{
            setPageLoader(true);
            var response = await Service.get('/get-recent-chat',{page});
            if(typeof response.data !== undefined){
                var res = response.data;
                if(typeof res.data!==undefined && res.status==='success'){
                    if(res.data.length){
                        setRecentChat(res.data);
                        setNextPage(res.nextPage)
                    }
                }
            }
            setPageLoader(false);
        }catch(error){
            setPageLoader(false);
            if(typeof error.response !=='undefined' && typeof error.response.status!=='undefined'){
                if(error.response.status===401){
                    storage.clear();
                    history.push('/login');
                }else{
                    notify.error(error.response.data.message);
                    return;
                }
            }else{
                notify.error(error.message,'Error');
                return;
            }
        }
    }
	
	useEffect(() => {
		getRecentChat();
	},[]);
    
    const RedirectTo=(id)=>{
       // history.push('/user-seller-chats/'+id);
    }
    return (
        <Page
            className=""
            title="Customer Support"
        >
            <Card>
                <CardBody>
                    {pageLoader? 
                            <Col className="text-center" ><Spinner /></Col>:
                    recentChat.length ?
                        <>
                            {recentChat.map((chatMap)=>{
                                return <ListGroupItem className="m-1 s-sort" style={{cursor:'pointer'}} key={chatMap.id} onClick={(e)=>RedirectTo(chatMap.id)}>
                                    <Row>
                                        {chatMap.orderProduct?
                                            <>
                                                <Col lg={2} md={2}>
                                                    <img className="br-10x" style={{width: "60px",height: "60px"}} src={chatMap.orderProduct.image} alt="Product"></img>
                                                </Col>
                                                <Col lg={3} md={3}>
                                                    <span className="detail-heading f-16"><b>Product : </b></span>
                                                    <div className="text-muted f-13">{chatMap.orderProduct.title}</div>
                                                    <div className="text-muted f-13">Order Id : {chatMap.orderProduct.orderId}</div>
                                                </Col>
                                            </>
                                        :<Col md={5} lg={5}></Col>}
                                        
                                        <Col lg={3} md={3}>
                                            <span className="detail-heading f-16"><b> User : </b></span>
                                            <div className="text-muted f-13">{chatMap.user.firstName} {chatMap.user.lastName}</div>
                                            <div className="text-muted f-13">@{chatMap.user.username}</div>
                                        </Col>

                                        <Col lg={3} md={3}>
                                            <div className="f-13"><b>Unread Count</b>  : <span className="text-muted"> {chatMap.unreadCount}</span></div>
                                            <div className="f-13"><b>Time</b> : <span className="text-muted">{moment(chatMap.updatedAt).format('MMM DD, YYYY hh:mm A')}</span></div>
                                        </Col>
                                    </Row>
                                </ListGroupItem>
                            })}
                        </>
                    :<Col className="text-center">No Record Found</Col>}
                </CardBody>
            </Card>
        </Page>
    )
}

export default Chatting;