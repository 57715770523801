import React,{useState,useEffect} from 'react';
import Page from '../Layout/Page';
import storage from '../utils/storage';
import notify from '../utils/notify';
import Service from '../Service';
import DataTable from "react-data-table-component";
import {
    Col,
    Row,
    Button,
    Card,
    Spinner,
    NavLink as BSNavLink,
    CardBody,
    CardHeader,
    Form,
    Input,
    InputGroup,
    Media
} from 'reactstrap';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { MdVisibility } from 'react-icons/md';
import { NavLink,useHistory } from 'react-router-dom';

const Orders = () => {
    const [ordersData ,setOrdersData ] = useState([]);
    const [currentPage,setCurrentPage]=useState(1);
    const [totalRecords,setTotalRecords]=useState(0);
    const [fromDate,setFromDate]=useState(null);
    const [toDate,setToDate]=useState(null);
    const [search,setSearch]=useState('');
    const [callApi,setCallApi]=useState(false);
    const [isLoad,setIsLoad]=useState(false);
    const history =useHistory();

    const getOrders=async () => {
        try {
            var response = await Service.post('/get-orders',{
                page:currentPage,
                q :search,
                fromDate : fromDate,
                toDate : toDate
            });
            if(typeof response.data !== undefined){
                var res = response.data;
                if(res.status==='success'){
                    setOrdersData(res.data);
                    setTotalRecords(res.count);
                    setIsLoad(false);
                }else{
                    setOrdersData([]);
                    setIsLoad(false);
                }
            }
        }catch(error){
            setIsLoad(false);
            if(typeof error.response !=='undefined' && typeof error.response.status!=='undefined'){
                if(error.response.status===401){
                    storage.clear();
                    history.push('/login');
                }else{
                    notify.error(error.response.data.message);
                    return;
                }
            }else{
                notify.error(error.message,'Error');
                return;
            }
        }
    }
    const handleDatePickerApply = (event, picker) => {
        picker.element.val(
          picker.startDate.format('MM/DD/YYYY') +
            ' - ' +
            picker.endDate.format('MM/DD/YYYY')
        );
        if(picker.startDate!==null){
            setFromDate(moment(picker.startDate._d).format('YYYY-MM-DD'));
        }else{
            setFromDate(null);
        }
        if(picker.endDate!==null){
            setToDate(moment(picker.endDate._d).format('YYYY-MM-DD'));
        }else{
            setToDate(null);
        }
    };
    const handleCancel = (event, picker) => {
        picker.element.val('');
        setFromDate(null);
        setToDate(null);
    };
    const handleInputChange = (input)=>{
        setSearch(input.value);
    }
    
    const submitSearch=()=>{
        setCurrentPage(1);
        setCallApi(!callApi);
    }
    const submitSearchForm = (e) =>{
        e.preventDefault();
        submitSearch();
    }
    useEffect(() => {
        setIsLoad(true);
        getOrders();
    },[callApi,currentPage]);
      
    
    const handlePageChange=(page)=>{
        setCurrentPage(page);
    }
    const columns = [
        {
          name: "Image",
          selector: function(data){
            return <Media style={{width:'30px',height:'30px'}} src={data.image} alt={data.title} />
          },
          sortable: false,
          wrap: true,
        },
        {
            name: "Order Id",
            selector: function(data){
                return <b className="text-muted">{'#'+data.orderId}</b>;
            },
            sortable: false,
            wrap: true,
          },
        {
          name: "Title",
          selector: function(data){
              return data.title;
          },
          sortable: false,
          wrap: true,
        },
        {
            name: "Status",
            selector: function(data){
                return data.status;
            },
            sortable: false,
            wrap: true,
        },
        {   
            name: "Color",
            selector: (row)=>{
                return <>
                    { row.color ? 
                        <div style={{display: 'flex'}}>
                            <div style={{
                                backgroundColor:row.color.colorCode,
                                width:'12px',
                                height:'12px',
                                borderRadius:'50%',
                                marginRight:'4px',
                            }}></div> <span className="margin-top: 5px;">{row.color.name} </span>
                            </div>
                    :''}
                </>
            },
            sortable: false,
            center : true
        },{   
            name: "Size",
            selector: (row)=>{
                return row.size
            },
            sortable: false,
            center : true
        },
        {
          name: "Selling Price",
          selector: function(data){
              return <span>${data.totalSellingPrice}</span>
          },
          sortable: false,
          wrap: true,
        }
        ,{
          name: "Created At",
          sortField: "createdAt",
          selector: (data)=>{
            return moment(data.createdAt).format('DD MMM, YYYY hh:mm A');
          },
          sortable: true,
          wrap: true,
        },
        {
            name: "Action",
            sortField:'action',
            selector: function(data){
                return <BSNavLink
                id={`order-detail-${data.orderId}`}
                className="btn btn-secondary btn-sm"
                tag={NavLink}
                style={{lineHeight:"0.8"}}
                to={`/order-detail/${data.orderId}`}
                activeClassName="active"
                exact={true}
              >
                <MdVisibility />
              </BSNavLink>
            },
            sortable: false,
            wrap:true,
        }
    ];
    const clearSearch=async ()=>{
        document.getElementById('datarangepicker').value='';
        setFromDate(null);
        setToDate(null);
        setSearch('');
        setCurrentPage(1);
        setCallApi(!callApi);
    }
    return(
        <Page
        className="ButtonPage"
        title="Orders"
        breadcrumbs={[{ name: 'buttons', active: true }]}
     >
       <Card>
            <CardHeader>
                <Row style={{marginTop:"13px",display:'flex',justifyContent:'end'}}>
                    <Col md={4} className="">
                        <DateRangePicker
                            startDate={fromDate}
                            endDate={toDate}
                            initialSettings={{
                                autoUpdateInput: false,
                                locale: {
                                    cancelLabel: 'Clear',
                                },
                            }}
                            locale={{ format: 'DD/MMM/YYYY' }}
                            onApply={handleDatePickerApply}
                            onCancel={handleCancel}
                        >
                            <input type="text" autoComplete="off" id="datarangepicker" placeholder="Choose Date.." className="form-control" />
                        </DateRangePicker>
                    </Col>
                    <Col md={4} className="">
                        <Form onSubmit={submitSearchForm}>
                            <InputGroup >
                                <Input value={search} placeholder="Search Here.." onChange={(e)=>{handleInputChange(e.target)}} />
                            </InputGroup>
                        </Form>
                    </Col>
					<Col md={4} className="">
                        <Form onSubmit={submitSearchForm}>
                            <InputGroup>
                                <Button color="secondary" className="mr-3" onClick={(e)=>{submitSearch()}}>Search</Button>
                                <Button color="dark t-white" onClick={clearSearch}>Clear</Button>
                            </InputGroup>
                        </Form>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col className="cate-table" lg={12} md={12} sm={12} xs={12}>
                        <DataTable
                            title=""
                            columns={columns}
                            responsive={true}
                            persistTableHead={true}
                            progressPending={isLoad}
                            progressComponent={<Spinner color="danger" />}
                            data={ordersData}
                            paginationRowsPerPageOptions={[10]}
                            pagination
                            paginationServer
                            paginationPerPage={10}
                            paginationTotalRows={totalRecords}
                            paginationDefaultPage={currentPage}
                            onChangePage={handlePageChange}
                            sortServer
                        />
                    </Col>
                </Row>
            </CardBody>
      </Card>      
     </Page>
    );
}

export default Orders;