import React,{useState,useEffect} from 'react';
import {useHistory,NavLink} from "react-router-dom";
import Service from '../Service';
import { MdVisibility,MdAdd, MdEdit} from 'react-icons/md';
import DataTable from "react-data-table-component";
import Page from '../Layout/Page';
import Select from 'react-select';
import {
    Col,
    Row,
    Input,
    InputGroup,
    InputGroupText,
    Button,
    Card,
    Spinner,
    Badge,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Form,
    FormGroup,
    Label,
    CardBody,
    CardHeader,
    FormText
} from 'reactstrap';
import notify from '../utils/notify';
import storage from '../utils/storage';

const Products = ()=>{
    const history =useHistory();
    const [callApi,setCallApi]=useState(false);
    const [pageLoad,setPageLoad]=useState(false);
    const [products,setProducts]=useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [productDetail,setProductDetail]=useState({});
    const [showEditModal,setEditModal]=useState(false);
    const [loaderEditModal,setLoaderEditModal]=useState(false);
    const [totalRecords,setTotalRecords]=useState(0);
    const [search,setSearch]=useState('');
    const [searchApproved,setSearchApproved]=useState('');
    const [searchStatus,setSearchStatus]=useState('');
    const [currentPage,setCurrentPage]=useState(1);
    const [shipChargeList,setShipChargeList]=useState([]);
    const statusOption=[{value:1,label:'For Sale'},{value:0,label:'Not For Sale'}];

    const getShippingCharge=async ()=>{
        try{
            const response = await Service.get('/get-delivery-charge');
            //console.log(response);
            if(typeof response.data !== undefined){
                const resdata = response.data;
                if(resdata.status==='success'){
                    setShipChargeList(resdata.data.charges);
                }
            }
        }catch(error){

        }
    }
    useEffect(()=>{
        getShippingCharge();
      },[]);
  
    const handleRowSelected = React.useCallback(state => {
        setSelectedRows(state.selectedRows);
    },[]);
    const columns = [
        {
          name: "Image",
          sortField: "image",
          selector: (row)=>{
            return <img style={{ width: "30px",height:"30px" }} src={row.image} alt=""/>;
          },
          sortable: false,
        },
        {
          name: "Title",
          sortField: "title",
          selector: (row) => row.title,
          sortable: false,
          wrap: true,
        },
        {
          name: "Max Price",
          sortField: "maxPrice",
          selector: (row) => "$"+row.maxPrice,
          sortable: false
        },
        {
          name: "Selling Price",
          sortField: "sellingPrice",
          selector: (row) => "$"+row.sellingPrice,
          sortable: false,
        },
        {
            name: "Approved",
            selector: (row)=>{
                if(row.isApproved===1){
                    return <Badge color="success" >APPROVED</Badge>;
                }else if(row.isApproved===2){
                    return <Badge color="danger" style={{backgroundColor : "#ddd"}}> REJECTED </Badge>;
                }else{
                    return <Badge color="warning">Pending</Badge>;
                }
            },
            sortable: false,
        },
        {
            name: "Status",
            selector: (row)=>{
                if(row.status === 1){
                return <Badge color="success" >ENABLED</Badge>
                }
                return <Badge color="danger" style={{backgroundColor : "#ddd"}}>DISABLED</Badge>;
            },
            sortable: false,
        },
        {
            name: "Action",
            sortField:'action',
            selector: function(data){
              return <div className="d-flex" style={{overflow:'unset'}}>
                    <Button
                        id={`edit-product-${data.id}`}
                        color="secondary t-white"
                        className="mr-1"
                        style={{lineHeight:"0.8"}}
                        onClick={()=>{editProduct(data.id)}}
                    >
                        <MdEdit />
                    </Button>
                    <Button
                        id={`product-detail-${data.id}`}
                        color="primary t-white"
                        tag={NavLink}
                        style={{lineHeight:"0.8"}}
                        to={`product-detail/${data.id}`}
                        exact={true}
                    >
                        <MdVisibility />
                    </Button>
                </div>
            },
            sortable: false,
            wrap : true,
            allowOverflow: true
        }
    ];
    const getProducts=async()=>{
        setPageLoad(true);
        try{
            var response = await Service.post('/get-products',{
                categoryId:[],
                page :currentPage,
                q : search,
                enableStatus : searchStatus,
                approvedStatus : searchApproved,
            });
            if(typeof response.data !== undefined){
                var resdata = response.data;
                setPageLoad(false);
                if(resdata.status==='success'){
                    setProducts(resdata.data);
                    setTotalRecords(resdata.count);
                }else{
                    setProducts([]);
                }
            }
        }catch(error){
            if(typeof error.response !=='undefined' && typeof error.response.status!=='undefined'){
                if(error.response.status===401){
                    storage.clear();
                    history.push('/login');
                }else{
                    notify.error(error.response.data.message);
                    return;
                }
            }else{
                notify.error(error.message,'Error');
                return;
            }
        }
    }
    useEffect(()=>{
        getProducts();
    },[callApi,currentPage,searchApproved,searchStatus]);
    const handleClick = async (id) => {
        if(selectedRows.length !== 0){
            const productId = selectedRows.map((item) => item.id);
            try{
                var response = await Service.post('/update-product-status',{status:id, productId:productId});
                if(typeof response.data !== undefined){
                    setCallApi(!callApi);
                }
            }catch(error){
                if(typeof error.response !=='undefined' && typeof error.response.status!=='undefined'){
                    if(error.response.status===401){
                        storage.clear();
                        history.push('/login');
                    }else{
                        notify.error(error.response.data.message);
                        return;
                    }
                }else{
                    notify.error(error.message,'Error');
                    return;
                }
            }
        }else{
            notify.error('Please Select Row')
        }
    }
    const toggle = () => {
        setEditModal((showEditModal) => !showEditModal)
    };
    const handleChangeInput=(input,key)=>{
        setProductDetail({...productDetail,[key]:input.value})
    }
    const changeAvailablity= (input)=>{
        setProductDetail({...productDetail,'status':input.value});
    }
    const handleSizeInput= async (input,sizeIndex)=>{
        var data=[];
        if(input.value>999){
            notify.error('Enter max 999 available quantity');
        }
        productDetail.sizes.map((sizes,index)=>{
          if(index===sizeIndex){
            sizes.available=input.value;
          }
          return data.push(sizes);
        })
        setProductDetail({...productDetail,'sizes':data});
    }
    const editProduct=async (productId)=>{
        setEditModal((showEditModal) => !showEditModal);
        setLoaderEditModal(true);
        setProductDetail({});
        setTimeout(async () => {
            var response = await Service.get('/product-detail',{productId: productId});
            if(response.status===200){
                setLoaderEditModal(false);
                setProductDetail(response.data.data);
            }
        }, 100);
        
    }
    const submitEditProduct = async (btn)=>{
        var setdata = {
            productId:productDetail.id,
            title:productDetail.title,
            maxPrice:productDetail.maxPrice,
            sellingPrice:productDetail.sellingPrice,
            deliveryChargeId:productDetail.deliveryChargeId,
            shippingCharge:(productDetail.shippingCharge?productDetail.shippingCharge:0),
            status:productDetail.status
        }
        var sizes=productDetail.sizes;
        var sizeData=[];
        var isError='';
        if(sizes.length){
          for(let i=0;i<sizes.length;i++){
            let newsize = sizes[i];
            if(isNaN(parseInt(newsize.available))){
              isError='Enter valid size';
              break;
            }
            if(parseInt(newsize.available)>999){
              isError='Enter max 999 available quantity';
              break;
            }
            sizeData.push({
              id:newsize.id,
              available:newsize.available
            });
          }
        }
        if(isError){
          notify.error(isError,'Error');
          return;
        }
        if(setdata.title===''){
            notify.error('Enter product name');
            return;
        }
        if(setdata.maxPrice===''){
            notify.error('Enter original price.');
            return;
        }else if(isNaN(parseFloat(setdata.maxPrice))){
            notify.error('Original price must be number or decimal.');
            return;
        }
        if(setdata.sellingPrice===''){
            notify.error('Enter listing price.');
            return;
        }else if(isNaN(parseFloat(setdata.sellingPrice))){
            notify.error('Selling price must be number or decimal.');
            return;
        }
        if(setdata.shippingCharge===''){
            notify.error('Enter shipping charge.','Error');
            return;
        }else if(isNaN(parseFloat(setdata.shippingCharge))){
            notify.error('Shipping price must be number or decimal.');
            return;
        }
        setdata.sizes=sizeData;
        try{
            btn.disabled=true;
            var response = await Service.post('/update-simple-products',setdata);
            if(response.status===200){
                toggle();
                btn.disabled=false;
                setProductDetail({});
            }
        }catch(error){
            btn.disabled=false;
            if(typeof error.response !=='undefined' && typeof error.response.status!=='undefined'){
                if(error.response.status===401){
                    storage.clear();
                    history.push('/login');
                }else{
                    notify.error(error.response.data.message);
                    return;
                }
            }else{
                notify.error(error.message,'Error');
                return;
            } 
        }

    }
    const clearSearch=async ()=>{
        setSearch('');
        setSearchStatus('');
        setSearchApproved('');
        setCurrentPage(1);
        setCallApi(!callApi);
    }
    const submitSearch=()=>{
        setCurrentPage(1);
        setCallApi(!callApi);
    }
    const handleInputChange = (input)=>{
        setSearch(input.value);
        submitSearch();
    }
    const handleSerchSelectChange = async (type, input)=>{
        if(type==='approved'){
            setSearchApproved(input.value);
        }else if(type==='status'){
            setSearchStatus(input.value);
        }
        setCurrentPage(1);
    }
    const handlePageChange=(page)=>{
        setCurrentPage(page);
    }
    return (
        <Page
          className=""
          title="Products"
          button={
            <div className="d-inline float-right">
              <Button color="primary t-white"  className="mr-1 btn-sm" data-toggle="tooltip" data-placement="top" title="Enable" onClick={() => handleClick(1)}>
                Enable
              </Button>
      
              <Button color="" style={{backgroundColor : "#ddd"}} className="mr-1 btn-sm" data-toggle="tooltip" data-placement="top" title="Disable" onClick={() => handleClick(0)}>
                Disable
              </Button>
              <Button
                id={`add-product`}
                className="btn-secondary"
                tag={NavLink}
                to={'add-product'}
                activeClassName="active"
                exact={true}
              >
                <MdAdd />Add
              </Button>
            </div>
          }
        >
            <Card>
                <CardHeader>
                    <Row style={{marginTop:"4px",justifyContent:'flex-start'}}>
                        <Col md={2} xs={6}>
                            <FormText>Approve </FormText>
                            <Input type="select" className="form-control" value={searchApproved} onChange={(e)=>{handleSerchSelectChange('approved',e.target)}} >
                                <option value={''}>All</option>
                                <option value={0}>Pending</option>
                                <option value={1}>Approved</option>
                                <option value={2}>Rejected</option>
                            </Input>
                        </Col>
                        
                        <Col md={2} xs={6}>
                            <FormText>Status </FormText>
                            <Input type="select" className="form-control" value={searchStatus} onChange={(e)=>{handleSerchSelectChange('status',e.target)}}>
                                <option value={''}>All</option>
                                <option value={1}>Enabled</option>
                                <option value={2}>Disabled</option>
                            </Input>
                        </Col>
                        <Col md={5} xs={12} className="">
                            <FormText>Search </FormText>
                            <InputGroup >
                                <Input value={search} placeholder="Search Here.." onChange={(e)=>{handleInputChange(e.target)}} />
                            </InputGroup>
                        </Col>
						<Col md={3} xs={12}>
                            <InputGroup className="mt29">
                                <FormText></FormText>
								<Button color="secondary" className="mr-3" onClick={(e)=>{submitSearch()}}>Search</Button>
                                <Button color="dark t-white" onClick={clearSearch}>Clear</Button>
                            </InputGroup>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row >
                        <Col className="cate-table" lg={12} md={12} sm={12} xs={12}>
                            <DataTable
                                title=""
                                columns={columns}
                                responsive={true}
                                persistTableHead={true}
                                progressPending={pageLoad}
                                progressComponent={<Spinner color="danger" />}
                                data={products}
                                paginationRowsPerPageOptions={[10]}
                                pagination
                                paginationPerPage={10}
                                paginationTotalRows={totalRecords}
                                paginationDefaultPage={currentPage}
                                paginationServer
                                onChangePage={handlePageChange}
                                selectableRows
                                onSelectedRowsChange={handleRowSelected}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Modal
                isOpen={showEditModal}
                toggle={toggle}
                size="lg"
            >
                <ModalHeader >Edit Product</ModalHeader>
                <ModalBody>
                    {loaderEditModal ?
                    <div style={{textAlign:'center'}}>
                        <Spinner />
                    </div>
                    :
                    <Form>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                <Label >Name <span className="text-danger">*</span></Label>
                                <Input type="text" name="title" onChange={(e)=>{handleChangeInput(e.target,'title')}} value={productDetail.title||''}  />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup className="edit-select">
                                <Label>Status</Label>
                                    <Select onChange={changeAvailablity} defaultValue={statusOption[productDetail.status?0:1]} options={statusOption} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                <Label >Original Price <span className="text-danger">*</span></Label>
                                <Input type="text" name="maxPrice"  onChange={(e)=>{handleChangeInput(e.target,'maxPrice')}} value={productDetail.maxPrice||''}  defaultValue={productDetail.maxPrice} />
                                </FormGroup>
                            </Col>
                            <Col  md={4}>
                                <FormGroup>
                                <Label >Listing Price <span className="text-danger">*</span></Label>
                                <Input type="text" name="sellingPrice" onChange={(e)=>{handleChangeInput(e.target,'sellingPrice')}} value={productDetail.sellingPrice||''}  defaultValue={productDetail.sellingPrice} />
                                </FormGroup>
                            </Col>
                            <Col  md={4}>
                                <FormGroup>
                                <Label >Shipping Charge <span className="text-danger">*</span></Label>
                                    <Input type="select" className="form-control" name="deliveryChargeId" onChange={(e)=>{handleChangeInput(e.target,'deliveryChargeId')}} value={productDetail.deliveryChargeId||''} defaultValue={productDetail.deliveryChargeId} >
                                        <option value={''}>Choose..</option>
                                        {shipChargeList.map(item=>{
                                            return <option key={item.id} value={item.id}>{item.text}</option>
                                        })}
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                            <Label>Sizes</Label>
                            </Col>
                            {productDetail.sizes && productDetail.sizes.map((sizes,index)=>{
                            return <Col md={4} key={sizes.id} style={{marginBottom:'5px'}}>
							    <InputGroup className="newlfex">
                                    <InputGroupText >{(sizes.prefix||'')+' '+sizes.size}</InputGroupText>
                                    <Input type="number" min={1} max={999} onChange={(e)=>{handleSizeInput(e.target,index)}} defaultValue={sizes.available} placeholder="Available"/>
                                </InputGroup>
                            </Col>
                            })}
                        </Row>
                    </Form>
                    }
                </ModalBody>
                {loaderEditModal===false &&
                    <ModalFooter>
                    <Button className="t-white"  color="primary" onClick={(e) =>{submitEditProduct(e.target)}}>
                        Submit
                    </Button>{' '}
                    <Button onClick={toggle} color="secondary">
                        Cancel
                    </Button>
                    </ModalFooter>
                }
            </Modal>
        </Page>
    )
}
export default Products;