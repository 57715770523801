import React from 'react';
import logo200Image from '../../assets/logo/logo.png';
import sidebarBgImage from '../../assets/sidebar/sidebar-4.jpg';
import SourceLink from './SourceLink';
import {
  MdDashboard,
  MdLocalPrintshop,
  MdKeyboardArrowDown,
  MdStore,
  MdSupervisorAccount,
  MdFollowTheSigns,
  MdLabelOutline,
  MdChat
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import {
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from '../utils/bemnames';
const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
};

const navItems = [
  { to: '/', name: 'dashboard', exact: true, Icon: MdDashboard },
  { to: '/store-info', name: 'Store Info', exact: true, Icon: MdStore },
  { to: '/products', name: 'Products', exact: false, Icon: MdLabelOutline },
];

const NavSales = [
  { to: '/orders',name : 'Orders',exact:true ,Icon: MdLocalPrintshop},
  { to: '/order-return-requests',name : 'Return Requests',exact:true ,Icon: MdLocalPrintshop},
  /* { to: '/order-replace-requests',name : 'Replace Requests',exact:true ,Icon: MdLocalPrintshop}, */
]
const navItems1 = [
  { to: '/earning', name: 'Earnings', exact: true, Icon: MdLabelOutline },
  { to: '/followers', name: 'Followers', exact: true, Icon: MdFollowTheSigns },
  { to: '/following', name: 'Following', exact: true, Icon: MdFollowTheSigns },
  { to: '/supports', name: 'Support', exact: true, Icon: MdChat },
];


const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  state = {
    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: true,
    isOpenSales: false,
    isOpenStores: false,
    isOpenCatalog: false,
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar className="sidemenu" sstyle={{justifyContent:'center'}}>
            <SourceLink className="navbar-brand d-flex mr11">
              <img
                src={logo200Image}
                height="50"
                className=""
                alt=""
              />
            </SourceLink>
          </Navbar>
          <Nav vertical>
            {navItems.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Sales')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdSupervisorAccount className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">SALES</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenSales
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenSales}>
              {NavSales.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>
            {navItems1.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
