import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const notify={
    error:(message) =>{
        return toast.error(message,{
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
    },
    success:(message) =>{
        return toast.success(message,{
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
    }
}
export default notify;